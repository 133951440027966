import React from 'react';
import {useNavigate} from 'react-router-dom';
import {AppRoutes} from "../../constants/AppRoutes";
import {AppConstants} from "../../constants/AppConstants";

export default function PaymentFailed() {
    const navigate = useNavigate();
    const goToDashboard = () => {
        if (pageLoadedInIFrame()) {
            // if its in iframe, emit an event
            const event = new CustomEvent(AppConstants.OnPaymentNavigateEvent);
            window.parent.document.dispatchEvent(event);
        } else {
            // else just navigate
            navigate(AppRoutes.accountAndBilling)
        }
    }

    const pageLoadedInIFrame = () => {
        return window.location !== window.parent.location;
    }

    return (
        <div className={pageLoadedInIFrame() ? "" : "auth-form"}>
            <div className="container h-100">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="form-inner">
                        <div className="auth-logo">
                            <img src="/images/lpc-logo.jpg" alt=""/>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div className="payment-confirmation-wrap">
                            <img src="/assets/icons/cancel-red.png" alt="" style={{height: '50px'}}/>
                            <br/>
                            <h4>Payment failed/cancelled!</h4>
                            <p>Please try again. If this issue persists, please contact LPC for assistance</p>
                            <button onClick={goToDashboard} className="btn btn-lpc text-uppercase">Go Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
