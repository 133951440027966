import {useState} from 'react';
import "./YocoPayment.css";
import {PaymentDetails} from "../../models/PaymentDetails";
import {toast} from "react-toastify";
import {ToastOptionsConst} from "../../constants/ToastOptionsConst";
import {AppConstants} from "../../constants/AppConstants";
import PaymentService from "../../services/PaymentService";
import {AppRoutes} from "../../constants/AppRoutes";
import {CustomerDetails} from "../../models/CustomerDetails";
import {useSelector} from "react-redux";

interface Props {
    payment: PaymentDetails.Payment,
    isLoading: boolean,
    setIsLoading: (value: boolean) => void,
    setPaymentStep: (value: PaymentDetails.PaymentStep) => void,
    setRedirectUrl: (value: string) => void
}

const paymentService = PaymentService.getInstance();

export default function YocoPayment({payment, isLoading, setIsLoading, setRedirectUrl, setPaymentStep}: Props) {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [checkoutSuccess, setCheckoutSuccess] = useState<boolean>(false);
    const {user}: { user: CustomerDetails.CustomerPersonalDetails } = useSelector((state: any) => state.user);

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    const payThisInvoice = async (event: any) => {
        setIsLoading(true);
        setErrorMessage("");
        const currentBaseUrl = window.location.origin;
        const request = {
            province: user.province,
            totalAmount: payment.totalAmount,
            paymentId: payment.paymentId,
            description: payment.description,
            cancelUrl: currentBaseUrl + AppRoutes.paymentFailed,
            failureUrl: currentBaseUrl + AppRoutes.paymentFailed,
            successUrl: currentBaseUrl + AppRoutes.paymentSuccess + "?category=" + payment.category
        } as PaymentDetails.PaymentCheckoutRequestModel;

        // call function
        await paymentService.getCheckoutDetails(request).then(yocoData => {
            if (!yocoData) {
                ErrorMsg(AppConstants.DefaultErrorMessage);
                setErrorMessage(AppConstants.DefaultErrorMessage);
                return;
            }

            setCheckoutSuccess(true);
            setErrorMessage("");
            listenToPaymentDocChangesFirestore();
            setTimeout(() => {
                setRedirectUrl(yocoData.redirectUrl);
                setPaymentStep("Redirect");
            }, 3000);
        }).catch(() => {
            ErrorMsg(AppConstants.DefaultErrorMessage)
            setIsLoading(false);
            setErrorMessage(AppConstants.DefaultErrorMessage);
        });
    }

    const listenToPaymentDocChangesFirestore = async () => {
        SuccessMsg("Checkout initiated, please wait..")
    }

    return (<>
            {payment && <div>
                <hr/>
                <div className="text-start payment-description">
                    <h4>{payment.invoiceNumber + " - " + payment.description}</h4>
                </div>
                <div className="text-start mt-3">
                    <div className="price-val color-main">R{payment.totalAmount}</div>
                </div>
                <hr/>
                <div className="text-start">
                    <div className="mt-3">
                        {!isLoading && errorMessage &&
                            <div className="p-lpc-error mt-3 text-center">{errorMessage}</div>}
                        {checkoutSuccess &&
                            <div className="mt-3 text-center">Please wait as we redirect you to payment page, please
                                don't close or refresh this page...</div>}
                        <button type="button" disabled={isLoading} onClick={payThisInvoice}
                                className="btn btn-lpc w-100 mt-3 text-uppercase">Pay
                            This Invoice (R{payment.totalAmount})
                        </button>
                    </div>
                </div>
            </div>}
        </>
    )
}
