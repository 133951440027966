import { useCallback, useEffect, useState } from 'react';
import './ProfileDetails.css';
import UserService from '../../services/UserService';
import { CustomerDetails } from "../../models/CustomerDetails";
import Loading from "../loading/Loading";
import { toast } from "react-toastify";
import { ToastOptionsConst } from "../../constants/ToastOptionsConst";
import { AppConstants } from "../../constants/AppConstants";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";

const userService = UserService.getInstance();
export default function ProfileDetails() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] =
    useState<CustomerDetails.MemberDetailsModel | null>(null);

  const { register, setValue, formState: { errors } } = useForm();

  const viewTableOfFirmClick = () => {
    navigate(AppRoutes.associationList);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await userService.getMemberDetails().then((x) => {
      setIsLoading(true);
      if (x) {
        setUserDetails(x);
      }
      setIsLoading(false);
    }).catch(() => {
      ErrorMsg(AppConstants.DefaultErrorMessage);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const ErrorMsg = (msg: string) => {
    toast.error(
      msg ?? AppConstants.DefaultErrorMessage,
      ToastOptionsConst.error
    );
  };

  if (isLoading) return <Loading />;

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <div className="layout-page">
          <div className="content-wrapper">
            <div className="d-flex align-items-stretch flex-grow-1 p-0">
              <div className="container-xxl flex-grow-1 container-p-y px-5">
                <div className="clear"></div>
                <h2 className="lpc-page-heading">Profile</h2>
                <div className="readonly ">
                  <p>
                    If you wish to update any information shown here,
                    <br /> please contact LPC
                  </p>
                </div>
                <form className='simple-form less-margin input-width-90'>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="control-label">FIRST NAME</label>
                      <input type="text" placeholder={userDetails?.name}
                        className="form-control"
                        disabled />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="control-label">LAST NAME</label>
                      <input type="text" value={userDetails?.surname} className="form-control"
                        disabled />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="control-label">EMAIL</label>
                      <input type="text" value={userDetails?.email} className="form-control"
                        disabled />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="control-label">PHONE NUMBER</label>
                      <input
                        type="text"
                        value={userDetails?.mobileNumber}
                        {...register('mobileNumber', {
                          pattern: {
                            value: /^0\d{9}$/,
                            message: 'Invalid phone number',
                          },
                        })}
                        className="form-control"
                        onChange={(e) => {
                          setValue('mobileNumber', e.target.value);
                        }}
                        maxLength={10}
                        disabled
                      />
                      <span className="error-message">
                        {errors.mobileNumber && errors.mobileNumber.message && (
                          <>{errors.mobileNumber.message}</>
                        )}
                      </span>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="control-label">PROVINCE</label>
                      <input type="text" value={userDetails?.province} className="form-control"
                        disabled />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="control-label">PRACTICING</label>
                      <input type="text" value={userDetails?.practising} className="form-control"
                        disabled />
                    </div>
                    <div className="form-group col-md-12 col-12 ">
                      <label className="control-label">PRACTIONER NUMBER</label>
                      <input type="text" value={userDetails?.memberNumber}
                        className="form-control w-100" disabled />
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" disabled
                          id="advocate" checked={userDetails?.advocate} />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          Advocate
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" disabled
                          id="attorney" checked={userDetails?.attorney} />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          Attorney
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" disabled
                          id="conveyancer" checked={userDetails?.conveyancer} />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          Conveyancer
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" disabled
                          id="notary" checked={userDetails?.notary} />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          Notary
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" disabled
                          id="trustAdvocate" checked={userDetails?.trustAdvocate} />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                          Trust Advocate
                        </label>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div className="button-groups d-flex flex-column justify-content-around">
                      <button
                        type="button"
                        className="btn btn-lpc mb-4"
                        onClick={viewTableOfFirmClick}
                        id="view-table"                      >
                        VIEW TABLE OF FIRMS
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
