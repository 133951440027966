import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomerDetails } from "../../models/CustomerDetails";
import UserService from "../../services/UserService";
import { AppConstants } from "../../constants/AppConstants";
import { ToastOptionsConst } from "../../constants/ToastOptionsConst";
import { AppRoutes } from "../../constants/AppRoutes";

const userService = UserService.getInstance();
export default function Register() {

    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        document.title = 'LPC - Register';
    }, []);


    const [formData, setFormData] = useState<CustomerDetails.RegistrationModel>({
        identityNumber: ""
    });

    const [validationErrors, setValidationErrors] = useState<CustomerDetails.RegistrationModel>({} as CustomerDetails.RegistrationModel);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const { identityNumber } = formData;
        const errors: CustomerDetails.RegistrationModel = {} as CustomerDetails.RegistrationModel;

        if (identityNumber.length !== 13) {
            errors.identityNumber = "Identity number must be 13 characters long.";
        }

        setValidationErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            await userService.memberRegistrationWithIdentityNumber(formData).then(async x => {
                switch (x.status) {
                    case 200:
                        SuccessMsg(AppConstants.PasswordResetSentToInbox);
                        setTimeout(() => {
                            setLoading(false);
                            navigate(AppRoutes.login);
                        }, 3000);
                        break;
                    case 404:
                    case 409:
                    case 500:
                        setLoading(false);
                        ErrorMsg(x.message);
                        setValidationErrors(errors);
                        break;
                }
            }).catch(err => {
                setLoading(false);
                ErrorMsg(AppConstants.AuthenticationErrorMap[err.code]);
                setValidationErrors(errors);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    return (

        <div className="auth-form">
            <div className="container h-100">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="form-inner">
                        <div className="auth-logo">
                            <img src="/images/lpc-logo.jpg" alt="" />
                        </div>
                        <h4>Create new Account</h4>
                        <p className='mb-0'>Already registered? <span className='green-font mb-0'
                            id="link-create-acc"><Link to="/login"
                                className="color-main">Login</Link></span>
                        </p>
                        <br />
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <input id="identityNumber" type="text" name="identityNumber"
                                            placeholder="ID Number"
                                            maxLength={13}
                                            className={`form-control ${validationErrors.identityNumber ? "is-invalid" : ""}`}
                                            value={formData.identityNumber}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {validationErrors.identityNumber && (
                                            <div className="invalid-feedback">
                                                {validationErrors.identityNumber}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-lpc text-uppercase">
                                    {isLoading ? "Submitting..." : "Register"}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </div>
    );
};
