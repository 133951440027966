import { getFunctions, httpsCallable, } from "firebase/functions";
import { CloudFunctions } from "../constants/CloudFunctions";
import { ContactUsDetails } from "../models/ContactUsDetails";
import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { FirestoreCollection } from "../constants/FirestoreCollection";
import { CommonModels } from "../models/CommonModels";

export default class ContactUsService {
    private static instance: ContactUsService | null = null;

    static getInstance(): ContactUsService {
        if (ContactUsService.instance === null) {
            ContactUsService.instance = new ContactUsService();
        }

        return this.instance as ContactUsService;
    }

    sendEmailToAdmin(sendEmailToAdminRequest: ContactUsDetails.ContactUsRequest): Promise<ContactUsDetails.SendEmailResponseModel> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.sendemail)
        const funcResponse = callable(sendEmailToAdminRequest)

        return funcResponse.then(x => {
            return x.data as ContactUsDetails.SendEmailResponseModel;
        });
    }

    getAdminContactDetailByProvince = async (province: CommonModels.ProvinceType): Promise<ContactUsDetails.AdminContactDetails | null> => {
        const docRef = doc(getFirestore(getAuth().app), FirestoreCollection.adminContactDetails, province);

        return getDoc(docRef).then(async res => {
            if (res.exists()) {
                return { ...res.data(), province : res.id }as ContactUsDetails.AdminContactDetails;
            }
            return null;
        })
    }
}
