import {FirestoreCollection} from "../constants/FirestoreCollection";
import {getAuth} from "firebase/auth";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    query,
    Timestamp,
    updateDoc,
    where
} from "firebase/firestore";
import {ApplicationDetails} from "../models/ApplicationDetails";
import {subDays} from "date-fns";
import {AppConstants} from "../constants/AppConstants";

export default class ApplicationService {
    static getApplicationListByUserId() {
        throw new Error('Method not implemented.');
    }
    private static instance: ApplicationService | null = null;

    static getInstance(): ApplicationService {
        if (ApplicationService.instance === null) {
            ApplicationService.instance = new ApplicationService();
        }

        return this.instance as ApplicationService;
    }

    async add(addDetail: ApplicationDetails.ApplicationModel): Promise<ApplicationDetails.ApplicationModel> {
        const currentUser = getAuth().currentUser;

        const detail = {
            ...addDetail,
            userId: currentUser?.uid,
            status: "NotPaid",
            updatedDate: Timestamp.now(),
        } as ApplicationDetails.ApplicationModel;

        const colRef = collection(getFirestore(getAuth().app), FirestoreCollection.applicationDetails);
        const docRef = await addDoc(colRef, detail);

        detail.applicationId = docRef.id;
        return detail;
    }

    async update(addDetail: ApplicationDetails.ApplicationModel, docid: string): Promise<ApplicationDetails.ApplicationModel> {

        const detail = {
            ...addDetail,
            updatedAt: new Date()
        } as ApplicationDetails.ApplicationModel;

        const colRef = doc(getFirestore(getAuth().app), FirestoreCollection.applicationDetails, docid);
        await updateDoc(colRef, { ...detail });
        return detail;
    }

    async getApplicationDetail(docid: string): Promise<ApplicationDetails.ApplicationModel | null> {
        const docRef = doc(getFirestore(getAuth().app), FirestoreCollection.applicationDetails, docid as string);
        return getDoc(docRef).then(async res => {
            if (res.exists()) {
                return res.data() as ApplicationDetails.ApplicationModel;
            }
            return null;
        })
    }

    getApplicationListByUserId = async (): Promise<Array<ApplicationDetails.ApplicationModel> | null> => {
        const currentUser = getAuth().currentUser;
        const fireStore = getFirestore(getAuth().app);
        const collectionRef = collection(fireStore, FirestoreCollection.applicationDetails);
        const queryRef = query(collectionRef, where("userId", "==", currentUser?.uid));
        const querySnapshot = await getDocs(queryRef);

        if (querySnapshot.empty) {
            return null;
        }

        const applicationList: Array<ApplicationDetails.ApplicationModel> = [];
        querySnapshot.forEach((doc) => {
            applicationList.push({ ...doc.data(), applicationId: doc.id } as ApplicationDetails.ApplicationModel)
        });

        return applicationList;
    };

    // Get paid payments within the last year
    getOneActiveApplicationInTheLastYear = async (): Promise<ApplicationDetails.ApplicationModel | null> => {
        const firestore = getFirestore(getAuth().app);
        const collectionRef = collection(firestore, FirestoreCollection.applicationDetails);
        const oneYearAgo = Timestamp.fromDate(subDays(new Date(), AppConstants.ApplicationActiveDaysCount));

        const querySnapshot = await getDocs(query(collectionRef,
            where('userId', '==',  getAuth().currentUser?.uid),
            where('status', '==', 'Active' as ApplicationDetails.ApplicationStatus),
            where('issuedDate', '>=', oneYearAgo),
            limit(1)
        ));

        if (querySnapshot.empty) {
            return null;
        }

        const applicationList: ApplicationDetails.ApplicationModel[] = [];

        querySnapshot.forEach((doc) => {
            applicationList.push({ ...doc.data(), applicationId: doc.id } as ApplicationDetails.ApplicationModel);
        });

        return applicationList[0];
    };
};
