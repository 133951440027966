import './AccountAndBilling.css';
import {useNavigate} from "react-router-dom";
import {AppRoutes} from "../../constants/AppRoutes";
import PaymentService from "../../services/PaymentService";
import React, {useCallback, useEffect, useState} from "react";
import {AppConstants} from "../../constants/AppConstants";
import {PaymentDetails} from "../../models/PaymentDetails";
import {toast} from "react-toastify";
import {ToastOptionsConst} from "../../constants/ToastOptionsConst";
import Loading from "../loading/Loading";
import {format} from 'date-fns';
import {CustomerDetails} from "../../models/CustomerDetails";
import {useSelector} from "react-redux";
import InlineLoading from "../loading/InlineLoading";

const paymentService = PaymentService.getInstance();
export default function AccountAndBilling() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [outstandingInvoicesList, setOutstandingInvoicesList] = useState<PaymentDetails.Payment[] | null>(null);
    const [paidInvoicesList, setPaidInvoicesList] = useState<PaymentDetails.Payment[] | null>(null);
    const [mmsInvoicesList, setMmsInvoicesList] = useState<PaymentDetails.Payment[] | null>(null);
    const {user}: { user: CustomerDetails.CustomerPersonalDetails } = useSelector((state: any) => state.user);

    const fetchOutstandingData = useCallback(async () => {
        paymentService.getPaymentListByUserId("AccountAndBilling")
            .then(data => {
                if (data) {
                    setOutstandingInvoicesList(data);
                } else {
                    setOutstandingInvoicesList([]);
                }
            })
            .catch(error => {
                ErrorMsg(AppConstants.DefaultErrorMessage);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const fetchMmsData = useCallback(async () => {
        paymentService.getMmsInvoiceList({memberNumber: user.memberNumber})
            .then(data => {
                if (data) {
                    setMmsInvoicesList(data);
                } else {
                    setMmsInvoicesList([]);
                }
            })
            .catch(error => {
                ErrorMsg(AppConstants.DefaultErrorMessage);
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [user.memberNumber]);

    const fetchPaidData = useCallback(async () => {
        setIsLoading(true);
        paymentService.getPaymentListByUserId("Paid")
            .then(data => {
                setPaidInvoicesList(data || []);
            })
            .catch(error => {
                console.error("Error fetching paid invoices:", error);
                ErrorMsg(AppConstants.DefaultErrorMessage);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    // Fetching Outstanding invoices
    useEffect(() => {
        fetchOutstandingData();
        fetchPaidData();
        fetchMmsData();
    }, [fetchOutstandingData, fetchPaidData, fetchMmsData]);

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    if (isLoading) return <Loading/>;

    return (
        <div className="layout-wrapper layout-content-navbar mb-5">
            <div className="layout-container">

                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                <br/>
                                <div className="row">
                                    <div className="col-md-10 col-12 mb-5">
                                        {/* Oustanding invoices */}
                                        <div className="d-flex justify-content-between">
                                            <div className="title-row">
                                                <h2 className="lpc-page-heading">Account & Billing
                                                    ({outstandingInvoicesList?.length ?? "..."})</h2>
                                            </div>
                                        </div>
                                        <PaymentDetailsList paymentList={outstandingInvoicesList}/>

                                        {/* MMS invoices */}
                                        <div className="d-flex justify-content-between mt-4">
                                            <div className="title-row">
                                                <h2 className="lpc-page-heading">MMS Invoices
                                                    ({mmsInvoicesList?.length ?? "..."})</h2>
                                            </div>
                                        </div>
                                        <PaymentDetailsList paymentList={mmsInvoicesList}/>

                                        {/* Paid invoices */}
                                        <div className="d-flex justify-content-between mt-4">
                                            <div className="title-row">
                                                <h2 className="lpc-page-heading">Paid Invoices
                                                    ({paidInvoicesList?.length ?? "..."})</h2>
                                            </div>
                                        </div>
                                        <PaymentDetailsList paymentList={paidInvoicesList}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface PaymentDetailsListProps {
    paymentList: Array<PaymentDetails.Payment> | null
}

const PaymentDetailsList = ({paymentList}: PaymentDetailsListProps) => {
    const navigate = useNavigate();

    if (paymentList === null) {
        return <InlineLoading/>
    }

    const payInvoiceNow = (payment: PaymentDetails.Payment) => {
        navigate(AppRoutes.payment + "?paymentId=" + payment.paymentId, {
            state: payment,
        });
    }

    return (
        <div className="table-border-wrap">
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Description</th>
                    <th>Total Amount</th>
                    <th>Action</th>
                    <th>Date Created</th>
                </tr>
                </thead>
                <tbody>
                {paymentList.length > 0 ? (
                    paymentList.map((p) => (
                        <tr key={p.invoiceNumber}>
                            <td>{p?.invoiceNumber ? p?.invoiceNumber + " - " + p.description : p.description}</td>
                            <td>
                                <div className="amount-row">
                                    <span>R{p.totalAmount}</span>
                                </div>
                            </td>
                            <td>
                                <div className="amount-row">
                                    {p.status === "Paid" && <span>Paid</span>}
                                    {(p.status === "Outstanding" || p.status === "Initiated") &&
                                        <button type="button" className="btn btn-lpc"
                                                onClick={() => payInvoiceNow(p)}>Pay Now
                                        </button>}
                                </div>
                            </td>
                            <td>{format(new Date(p.createdDate?.toDate()), "yyyy-MM-dd")}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={5}>No record found!</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}
