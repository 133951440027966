import {useEffect, useRef, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import ProtectedRoute from "./ProtectedRoute";
import GoodStanding from "./components/good-standing/GoodStanding";
import AccountAndBilling from "./components/account-and-billing/AccountAndBilling";
import ApplicationForm from "./components/application-form/ApplicationForm";
import Payment from "./components/payment/Payment";
import ProfileDetails from "./components/profile/ProfileDetails";
import Admissions from "./components/admissions/Admissions";
import Registration from "./components/registration/Registration";
import Login from "./components/login/LoginPage";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import {firebaseConfig} from "./constants/FirebaseConfigs";
import {AppRoutes} from "./constants/AppRoutes";
import {getApps, initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {setLogout} from "./slice";
import {useDispatch} from "react-redux";
import Loading from './components/loading/Loading';
import AssociationList from './components/associations/Associations';
import MemberConfirmation from "./components/registration/MemberConfirmation";
import {ToastContainer} from "react-toastify";
import PaymentSuccess from "./components/payment/PaymentSuccess";
import PaymentFailed from "./components/payment/PaymentFailed";
import Layout from './components/layout/Layout';
import Applications from './components/applications/applications';
import Help from './components/help/Help';
import 'bootstrap/dist/css/bootstrap.min.css';
import VerifyCertificate from "./public-pages/verify-certificate/VerifyCertificate";

function App() {
    const initialRoute = useRef<string>(AppRoutes.login);
    const [initializing, setInitializing] = useState<boolean>(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!getApps() || getApps().length === 0) {  // only initialize firebase app once
            initializeApp(firebaseConfig);
            getAuth().onAuthStateChanged((user) => {
                if (user === null) {
                    initialRoute.current = AppRoutes.login;
                    dispatch(setLogout());
                } else {
                    initialRoute.current = AppRoutes.goodStanding;
                }

                if (initializing) {
                    setInitializing(false);
                }
            });
        }
    });

    if (initializing) {
        return <Loading></Loading>
    }

    return (
        <BrowserRouter>
            <div>
                <Routes>
                    <Route path='/' element={<Layout />}>
                        <Route path={AppRoutes.goodStanding} element={
                            <ProtectedRoute>
                                <GoodStanding />
                            </ProtectedRoute>
                        } />

                        <Route path={AppRoutes.applications} element={
                            <ProtectedRoute>
                                <Applications />
                            </ProtectedRoute>
                        } />

                        <Route path={AppRoutes.accountAndBilling} element={
                            <ProtectedRoute>
                                <AccountAndBilling />
                            </ProtectedRoute>
                        } />
                        <Route path={AppRoutes.applicationDetails} element={
                            <ProtectedRoute>
                                <ApplicationForm />
                            </ProtectedRoute>
                        } />
                        <Route path={AppRoutes.editApplication} element={
                            <ProtectedRoute>
                                <ApplicationForm />
                            </ProtectedRoute>
                        } />
                        <Route path={AppRoutes.payment} element={
                            <ProtectedRoute>
                                <Payment />
                            </ProtectedRoute>
                        } />

                        <Route path={AppRoutes.associationList} element={
                            <ProtectedRoute>
                                <AssociationList />
                            </ProtectedRoute>
                        } />
                        <Route path={AppRoutes.profile} element={
                            <ProtectedRoute>
                                <ProfileDetails />
                            </ProtectedRoute>
                        } />
                        <Route path={AppRoutes.admission} element={
                            <ProtectedRoute>
                                <Admissions />
                            </ProtectedRoute>
                        } />
                        {/*<Route path={AppRoutes.admissionDetails} element={*/}
                        {/*    <ProtectedRoute>*/}
                        {/*        <AdmissionDetails />*/}
                        {/*    </ProtectedRoute>*/}
                        {/*} />*/}
                        {/*  <Route path={AppRoutes.associationDetails} element={*/}
                        {/*    <ProtectedRoute>*/}
                        {/*        <AssociationDetails />*/}
                        {/*    </ProtectedRoute>*/}
                        {/*} />*/}
                        <Route path={AppRoutes.help} element={
                            <ProtectedRoute>
                                <Help/>
                            </ProtectedRoute>
                        } />
                        <Route path="/" element={
                            <Navigate to={AppRoutes.goodStanding} />
                        } />
                    </Route>

                    <Route path={AppRoutes.paymentSuccess} element={
                        <ProtectedRoute>
                            <PaymentSuccess />
                        </ProtectedRoute>
                    } />

                    <Route path={AppRoutes.paymentFailed} element={
                        <ProtectedRoute>
                            <PaymentFailed />
                        </ProtectedRoute>
                    } />

                    <Route path={AppRoutes.register} element={<Registration />} />
                    <Route path={AppRoutes.login} element={<Login />} />
                    <Route path={AppRoutes.forgotPassword} element={<ForgotPassword />} />
                    <Route path={AppRoutes.memberConfirmation} element={<MemberConfirmation />} />

                    {/*Public Routes*/}
                    <Route path={AppRoutes.verifyCertificate} element={<VerifyCertificate />} />

                    <Route path="/*" element={
                        <Navigate to={AppRoutes.goodStanding} />
                    } />
                </Routes>
                <ToastContainer />
            </div>
        </BrowserRouter>
    );
}

export default App;


