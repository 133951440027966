import {useCallback, useEffect, useState} from 'react';
import './ApplicationForm.css';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {ApplicationDetails} from '../../models/ApplicationDetails';
import {toast, ToastContainer} from 'react-toastify';
import ApplicationService from '../../services/ApplicationService';
import {AppConstants} from '../../constants/AppConstants';
import {SubmitHandler, useForm} from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import {ToastOptionsConst} from '../../constants/ToastOptionsConst';
import {useSelector} from "react-redux";
import {CustomerDetails} from "../../models/CustomerDetails";
import UserService from '../../services/UserService';
import InlineLoading from '../loading/InlineLoading';
import {AppRoutes} from '../../constants/AppRoutes';
import {PaymentDetails} from '../../models/PaymentDetails';
import PaymentService from "../../services/PaymentService";

const applicationService = ApplicationService.getInstance();
const paymentService = PaymentService.getInstance();
const userService = UserService.getInstance();

const ApplicationForm = () => {

    const navigate = useNavigate();
    const [agree, setAgree] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [percentage, setPercentage] = useState<number>(0);
    const [isLoading, setLoading] = useState(false);
    const [bussinessRulesPass, setBussinessRulesPass] = useState<boolean | null>(null); // null indicate that a call has not been made yet
    const {user}: { user: CustomerDetails.CustomerPersonalDetails } = useSelector((state: any) => state.user);

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors}
    } = useForm<ApplicationDetails.ApplicationModel>();

    const initializeCallback = useCallback(async () => {
        // try GET any certificate paid in the past 365 days, if exist redirect to good standing page
        const activeApplication = await applicationService.getOneActiveApplicationInTheLastYear();
        if (activeApplication !== null) {
            setLoading(false);
            SuccessMsg("You have one Active Good Standing Certificate. Download now");
            navigate(AppRoutes.goodStanding);
            return;
        }

        // check if there's any with pending payment, then redirect to good-standing
        const pendingPaymentDetail = await paymentService.getOneGSOutstandingOrInitiatedPaymentInTheLastYear();
        if (pendingPaymentDetail !== null) {
            setLoading(false);
            SuccessMsg("You have one Outstanding payment. Pay now");
            navigate(AppRoutes.goodStanding);
            return;
        }

        // then check for business rules
        await userService.getBussinessRules({memberNumber: user.memberNumber}).then(response => {
            const pass = userService.isBusinessRulesPass(response); // exchange for a business rule check
            setBussinessRulesPass(pass);

            if (!pass) {  // All required business rules must pass.
                ErrorMsg(AppConstants.BussinessRulesMustPass);
                navigate(AppRoutes.goodStanding);
                return;
            } else {
                // Allow user to start application creation
                SuccessMsg("All business rules passed!")
            }
        }).catch(() => {
            ErrorMsg(AppConstants.DefaultErrorMessage)
            setBussinessRulesPass(false);
            setErrorMessage(AppConstants.DefaultErrorMessage);
        });
    }, [user, navigate]);

    useEffect(() => {
        initializeCallback();
    }, [initializeCallback]);

    const onSubmit: SubmitHandler<ApplicationDetails.ApplicationModel> = async (data: ApplicationDetails.ApplicationModel) => {
        if (!agree) {
            setErrorMessage('This field is required!');
            return true;
        }
        setErrorMessage('');
        setLoading(true);

        // memberNumber is needed for business rules check late
        data.memberNumber = user.memberNumber;

        await applicationService.add(data).then(res => {
            if (res) {
                setLoading(false);
                SuccessMsg("Application is added successfully!");
                // navigate to payment screen and create new payment
                const paymentCategory = "GS" as PaymentDetails.PaymentCategory;
                navigate(AppRoutes.payment + "?isNew=true&category=" + paymentCategory + "&categoryRefId=" + res.applicationId);
            } else {
                ErrorMsg(AppConstants.DefaultErrorMessage);
            }
        }).catch(err => {
            setLoading(false);
            ErrorMsg(AppConstants.AuthenticationErrorMap[err.code]);
        });
        setLoading(false);
    };

    const fetchMemberDetails = useCallback(async () => {
        setLoading(true);
        await userService.getMemberDetails().then((x) => {
            setLoading(true);
            if (x) {
                setValue('firstName', x.name);
                setValue('surname', x.surname);
                setValue('mobileNumber', x.mobileNumber);
                setValue('email', x.email);
                setValue('memberNumber', x.memberNumber);
                setValue('province', x.province);
                setPercentage(100);
            }
            setLoading(false);
        }).catch(() => {
            ErrorMsg(AppConstants.DefaultErrorMessage);
            setLoading(false);
        });
    }, [setValue]);

    useEffect(() => {
        // GET personal details
        fetchMemberDetails();
    }, [fetchMemberDetails])

    const handleCheckboxChange = () => {
        setAgree(!agree);
        setErrorMessage('');
    };

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                <div className="create-application-page">

                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="d-flex align-items-center">
                                            <div className="cert-progress">
                                                <CircularProgressbar strokeWidth={6} value={percentage}
                                                                     styles={buildStyles({
                                                                         pathColor: `rgba(1, 101, 51, ${percentage / 100})`,
                                                                         textColor: '#fff',
                                                                         trailColor: '#B4B4B4',
                                                                         // backgroundColor: '#3e98c7',
                                                                     })}/>
                                            </div>
                                            <div className="text-start admission-details-title">
                                                <h2 className="lpc-page-heading">Good Standing Certificate
                                                    Application</h2>
                                            </div>
                                        </div>
                                    </div>
                                    {bussinessRulesPass == null && <div><InlineLoading/>
                                        <p>
                                            Checking business rules, please wait...
                                        </p></div>}
                                    {bussinessRulesPass &&
                                        <form method="post" className="simple-form" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="d-flex flex-input">
                                                <div className="flex-input-left w-50">
                                                    <div
                                                        className={`form-group ${errors.firstName ? 'has-error' : ''} `}>
                                                        <input type="text" disabled
                                                               className="form-control" {...register("firstName", {required: true})}
                                                               placeholder="First Name"/>
                                                    </div>
                                                </div>
                                                <div className="flex-input-right w-50">
                                                    <div className={`form-group ${errors.surname ? 'has-error' : ''} `}>
                                                        <input type="text" {...register("surname", {required: true})}
                                                               className="form-control" placeholder="Surname" disabled/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-input">
                                                <div className="flex-input-right w-50">
                                                    <div
                                                        className={`form-group ${errors.memberNumber ? 'has-error' : ''} `}>
                                                        <input
                                                            type="text" {...register("memberNumber", {required: true})}
                                                            className="form-control" disabled
                                                            placeholder="Practioner Number"/>
                                                    </div>
                                                </div>
                                                <div className="flex-input-left w-50">
                                                    <div
                                                        className={`form-group ${errors.mobileNumber ? 'has-error' : ''} `}>
                                                        <input type="text"
                                                               {...register("mobileNumber", {
                                                                   maxLength: 10,
                                                                   minLength: 10
                                                               })}
                                                               className="form-control" disabled
                                                               placeholder="Mobile No (0712345678)"
                                                        />
                                                        {errors.mobileNumber &&
                                                            <span>{errors.mobileNumber.message}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-input">
                                                <div className="flex-input-right w-50">
                                                    <div className={`form-group ${errors.email ? 'has-error' : ''} `}>
                                                        <input
                                                            type="email"
                                                            {...register("email", {required: true})}
                                                            className="form-control" disabled
                                                            placeholder="Email Address"
                                                        />
                                                        {errors.email && <span>{errors.email.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="flex-input-right w-50">
                                                    <div className={`form-group ${errors.email ? 'has-error' : ''} `}>
                                                        <input
                                                            type="text"
                                                            {...register("province", {required: true})}
                                                            className="form-control" disabled
                                                            placeholder="Province"
                                                        />
                                                        {errors.province && <span>{errors.province.message}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-check text-start">
                                                <input className="form-check-input" type="checkbox" value=""
                                                       id="flexCheckChecked" checked={agree}
                                                       onChange={handleCheckboxChange}/>
                                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                                    I agree that the information above is correct
                                                </label>
                                            </div>
                                            <div className="errMsg">{errorMessage}</div>
                                            <br/>
                                            <div className="text-start mt-2">
                                                <button className='btn btn-lpc btn-round'>
                                                    {isLoading ? "Submitting..." : "Continue"}
                                                </button>
                                            </div>
                                        </form>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>

    );
};

export default ApplicationForm;
