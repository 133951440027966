import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoggedIn, setLogin } from '../../slice';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginPage.css';
import 'react-toastify/dist/ReactToastify.css';
import UserService from "../../services/UserService";
import { CustomerDetails } from "../../models/CustomerDetails";
import { AppConstants } from "../../constants/AppConstants";
import { ToastOptionsConst } from '../../constants/ToastOptionsConst';

const userService = UserService.getInstance();

export default function Login() {
    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'LPC - Login';
        if (user.isLoggedIn) {
            navigate("/");
        }
    }, [user, navigate]);

    const [isLoading, setLoading] = useState(false);

    const [formData, setFormData] = useState<CustomerDetails.SignInCredentials>({
        identityNumber: "",
        password: ""
    } as CustomerDetails.SignInCredentials);

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const dispatch = useDispatch();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        await userService.signInWithEmailAndPassword(formData).then(async x => {
            const data = await userService.getLoginPersonalDetail();
            if (data) {
                setLoading(false);
                SuccessMsg("Successfully logged in!");
                dispatch(setLogin(data));
                dispatch(setIsLoggedIn());

                setTimeout(() => {
                    navigate("/");
                }, 2000);
            } else {
                ErrorMsg(AppConstants.DefaultErrorMessage);
            }
        }).catch(err => {
            setLoading(false);
            ErrorMsg(AppConstants.AuthenticationErrorMap[err.code]);
        });
        setLoading(false);
    };

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    return (


        <div className="auth-form">
            <div className="container h-100">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="form-inner">

                        <div className="auth-logo">
                            <img src="/images/lpc-logo.jpg" alt="" />
                        </div>

                        <h4>Login</h4>

                        <p className='mb-0'>Are you a new user? <span className='green-font mb-0'
                            id="link-create-acc"><Link to="/register"
                                className="color-main">Create an account.</Link></span>
                        </p>

                        <br />

                        <form onSubmit={handleSubmit}>

                            <div className="form-group">
                                <input id="identityNumber" type="text" className="form-control" name="identityNumber"
                                    placeholder="ID Number"
                                    maxLength={13}
                                    value={formData.identityNumber}
                                    onChange={handleInputChange}
                                    required />
                            </div>

                            <div className="form-group">
                                <input id="password" type="password" className="form-control" name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required />
                            </div>

                            <div className="text-center">
                                <button type="submit" className="btn btn-lpc text-uppercase">
                                    {isLoading ? "Submitting..." : "Login"}
                                </button>
                                <div className="mb-3 mt-3">
                                    <Link className="color-main text-uppercase" to="/forget-password">
                                        Forgot Password?
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </div>


    );
}
