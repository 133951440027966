import "./GoodStanding.css";
import {Link, useNavigate} from "react-router-dom";
import {AppRoutes} from "../../constants/AppRoutes";
import {useCallback, useEffect, useState} from "react";
import UserService from "../../services/UserService";
import {CustomerDetails} from "../../models/CustomerDetails";
import {useSelector} from "react-redux";
import {AppConstants} from "../../constants/AppConstants";
import CertificateService from "../../services/CertificatesService";
import {toast} from "react-toastify";
import {ToastOptionsConst} from "../../constants/ToastOptionsConst";
import ApplicationService from "../../services/ApplicationService";
import PaymentService from "../../services/PaymentService";
import {PaymentDetails} from "../../models/PaymentDetails";
import {CertificateDetails} from "../../models/CertificateDetails";
import {ApplicationDetails} from "../../models/ApplicationDetails";

type GoodstandingButtonType = "DownloadNow" | "ApplyNow" | "PayNow" | "ErrorMessage" | "None";
const userService = UserService.getInstance();
const certificateService = CertificateService.getInstance();
const applicationService = ApplicationService.getInstance();
const paymentService = PaymentService.getInstance();

const GoodStanding = () => {
    const [buttonToShow, setButtonToShow] = useState<GoodstandingButtonType>("None");
    const [businessRules, setBusinessRules] = useState<CustomerDetails.BusinessRulesModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const {user}: { user: CustomerDetails.CustomerPersonalDetails } = useSelector((state: any) => state.user);
    const [pendingPayment, setPendingPayment] = useState<PaymentDetails.Payment | null>(null);
    const [activeApplication, setActiveApplication] = useState<ApplicationDetails.ApplicationModel | null>(null);
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            // check business rules if they are passing
            const rules = await userService.getBussinessRules({memberNumber: user.memberNumber});
            setBusinessRules(rules);

            // try GET any certificate paid in the past 365 days and Business rules pass
            const activeApp = await applicationService.getOneActiveApplicationInTheLastYear();
            if (activeApp !== null && userService.isBusinessRulesPass(rules)) {
                setActiveApplication(activeApp);
                setButtonToShow("DownloadNow");
                setIsLoading(false);
                return;
            }

            // check if there's any with pending payment, then redirect to payment
            const pendingPaymentDetail = await paymentService.getOneGSOutstandingOrInitiatedPaymentInTheLastYear();
            if (pendingPaymentDetail !== null) {
                setPendingPayment(pendingPaymentDetail);
                setButtonToShow("PayNow");
                setIsLoading(false);
                return;
            }

            // else if business rules pass, allow them to apply
            if (userService.isBusinessRulesPass(rules)) {
                setButtonToShow("ApplyNow");
                setIsLoading(false);
                return;
            }

            setErrorMessage(AppConstants.BussinessRulesMustPass);
            setIsLoading(false);
        } catch (error) {
            setButtonToShow("ErrorMessage");
            setErrorMessage(AppConstants.DefaultErrorMessage);
            setIsLoading(false);
        }
    }, [user]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const downloadCertificate = () => {
        setIsDownloading(true);
        const requestDetail = {
            memberNumber: user.memberNumber,
            applicationId: activeApplication?.applicationId,
            applicationNumber: activeApplication?.applicationNumber
        } as CertificateDetails.CertificateGeneratorRequestModel;

        certificateService.downloadCertificate(requestDetail)
            .then(blob => {
                // Create a new URL for the blob object
                const url = window.URL.createObjectURL(new Blob([blob]));
                // Create a link element
                const link = document.createElement('a');
                link.href = url;
                // Set the download attribute with a filename
                const filename = user.name + "_" + user.memberNumber + '_certificate.pdf';
                link.setAttribute('download', filename);
                // Append the link to the body
                document.body.appendChild(link);
                // Simulate a click on the link
                link.click();
                // // Clean up and remove the link
                link.parentNode?.removeChild(link);
                // // Revoke the object URL
                window.URL.revokeObjectURL(url);
                setIsDownloading(false);
            }).catch(err => {
            ErrorMsg();
            setIsDownloading(false);
        })
    }

    const payPendingPaymentNow = () => {
        if (!pendingPayment) ErrorMsg("There's no pending payment currently, please try again later.");
        navigate(AppRoutes.payment + "?paymentId=" + pendingPayment?.paymentId, {
            state: pendingPayment,
        });
    }

    const ErrorMsg = (msg?: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                {/* main content will start from here */}
                                <div className="dashboard">
                                    <div className="application-percentage">
                                        <div className="percentage-box-inner">
                                            <div className="d-flex justify-content-between">
                                                <div className="good-standing-box">
                                                    <h3>
                                                        Good Standing Certificate
                                                    </h3>

                                                    {isLoading && <p className="good-standing-loading-text">
                                                        Loading...
                                                    </p>}

                                                    {buttonToShow === "DownloadNow" && !isLoading && (
                                                        <>
                                                            <button disabled={isDownloading}
                                                                    className="btn btn-white"
                                                                    onClick={downloadCertificate}>
                                                                {isDownloading ? "Downloading..." : "Download Now"}
                                                            </button>
                                                            <p className="good-standing-loading-text mt-2">
                                                                *The certificate will display the most recent profile
                                                                details.
                                                            </p>
                                                        </>
                                                    )}

                                                    {buttonToShow === "PayNow" && !isLoading && (
                                                        <button className="btn btn-white"
                                                                onClick={payPendingPaymentNow}>
                                                            Pay Now
                                                        </button>
                                                    )}

                                                    {buttonToShow === "ApplyNow" && !isLoading && (
                                                        <Link
                                                            to={AppRoutes.applicationDetails}
                                                            className="btn btn-white"
                                                        >
                                                            Apply Now
                                                        </Link>
                                                    )}

                                                    {errorMessage && !isLoading &&
                                                        <p className="good-standing-error-text mt-2">
                                                            {errorMessage}
                                                        </p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <h1 className="lpc-page-heading mt-4">Business Rule Checks</h1>
                                    {businessRules && <BusinessRuleCard businessRules={businessRules}/>}
                                    <p className="business-rule-info">
                                        <br/><strong>1.20 "Good standing"</strong> means, in relation to a legal
                                        practitioner <br/>
                                        <strong> 1.20.1 </strong>that the name of the legal practitioner is on the roll
                                        of legal practitioners and that he or
                                        she has not been suspended from practice;<br/>
                                        <strong> 1.20.2 </strong> that there are no proceedings pending or contemplated
                                        to remove the name of the legal
                                        practitioner from the roll of legal practitioners or to suspend him or her from
                                        practice;<br/>
                                        <strong>1.20.3 </strong>that where the legal practitioner is required to be in
                                        possession of a Fidelity Fund
                                        certificate, he or she is in possession of a valid Fidelity Fund certificate;
                                        and<br/>
                                        <strong>1.20.4 </strong>that the legal practitioner is up to date with all
                                        amounts owed by him or her to the Council.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface BusinessRuleCardProps {
    businessRules: CustomerDetails.BusinessRulesModel
}

type BusinessRulesTitleMapType = keyof Omit<CustomerDetails.BusinessRulesModel, "memberNumber" | "checkRequiredFfc">;
export const BusinessRulesModelTitleMap: Record<BusinessRulesTitleMapType, string> = {
    checkInvoice: "Account & Billing",
    checkSuspension: "Suspension",
    checkStriking: "Striking",
    checkRedflag: "Red Flag",
    checkFfc: "FFC",
}

const BusinessRuleCard = ({businessRules}: BusinessRuleCardProps) => {
    return (
        <div className="row">
            {Object.entries(businessRules).map((entry) => {
                const key = entry[0];
                const value = entry[1];
                if (key === "memberNumber" || key === "checkRequiredFfc" || (key === "checkFfc" && !businessRules.checkRequiredFfc) ) return (<></>);

                return (<div className="col-md-3 mb-4" key={key}>
                    <div className={"business-rule-card " + (value ? "business-rule-card-background" : "")}>
                        <p>{BusinessRulesModelTitleMap[key as BusinessRulesTitleMapType]}</p>
                        <h4 className={value ? "" : "business-rule-card-failed-text"}>{value ? "PASS" : "FAIL"}</h4>
                        {!value && <>
                            {key === 'checkFfc' ? <Link className="business-rule-card-link" target="_blank"
                                                        to={AppConstants.ffcApplyLink}>Apply for the FFC</Link> :
                                key === 'checkInvoice' || key === 'checkGoodStandingPayment' ?
                                    <Link className="business-rule-card-link" to={AppRoutes.accountAndBilling}>Go To
                                        Account & Billing</Link> : <Link className="business-rule-card-link" to={AppRoutes.help}>Contact LPC</Link>} </>}
                    </div>
                </div>)
            })}
        </div>)
}

export default GoodStanding;
