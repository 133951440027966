import {getFunctions, httpsCallable} from "firebase/functions";
import {CloudFunctions} from "../constants/CloudFunctions";
import {FirmAdmissionDetails} from "../models/AdmissionDetails";
import {getAuth} from "firebase/auth";
import {addDoc, collection, doc, getDoc, getFirestore, Timestamp, updateDoc} from "firebase/firestore";
import {FirestoreCollection} from "../constants/FirestoreCollection";
import {getStorage, ref, uploadBytesResumable, getDownloadURL,} from "firebase/storage";
import {FirebaseStoragePath} from "../constants/FirebaseStoragePath";

export default class AdmissionServices {
    private static instance: AdmissionServices | null = null;

    static getInstance(): AdmissionServices {
        if (AdmissionServices.instance === null) {
            AdmissionServices.instance = new AdmissionServices();
        }

        return this.instance as AdmissionServices;
    }

    async getAllFirmAdmissions(
        memberNumber: number
    ): Promise<FirmAdmissionDetails.AdmissionDetail[]> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.admission);
        const funcRef = callable({memberNumber});

        return funcRef
            .then((res) => {
                const responseModel =
                    res.data as FirmAdmissionDetails.AssociationResponseModel;
                return responseModel.data as FirmAdmissionDetails.AdmissionDetail[];
            })
            .catch((error) => {
                return [];
            });
    }

    async add(admissionDetail: FirmAdmissionDetails.AdmissionDetail): Promise<FirmAdmissionDetails.AdmissionDetail> {
        const currentUser = getAuth().currentUser;

        const detail = {
            ...admissionDetail,
            userId: currentUser?.uid,
            status: "Pending",
            createdDate: Timestamp.now(),
            updatedDate: Timestamp.now(),
        } as FirmAdmissionDetails.AdmissionDetail;

        const colRef = collection(getFirestore(getAuth().app), FirestoreCollection.admissionsDetails);
        const docRef = await addDoc(colRef, detail);

        // Return new doc id
        detail.admissionId = docRef.id;

        return detail;
    }

    async update(updateAdmission: FirmAdmissionDetails.AdmissionDetail, docId: string): Promise<FirmAdmissionDetails.AdmissionDetail> {

        const detail = {
            ...updateAdmission,
            updatedAt: new Date()
        } as FirmAdmissionDetails.AdmissionDetail;

        const colRef = doc(getFirestore(getAuth().app), FirestoreCollection.admissionsDetails, docId);
        await updateDoc(colRef, {...detail});
        return detail;
    }

    async getAdmissionDetailById(admissionId: string): Promise<FirmAdmissionDetails.AdmissionDetail | null> {
        const docRef = doc(getFirestore(getAuth().app), FirestoreCollection.admissionsDetails, admissionId);
        return getDoc(docRef).then(async res => {
            if (res.exists()) {
                return res.data() as FirmAdmissionDetails.AdmissionDetail;
            }
            return null;
        })
    }

    async uploadHighCourtCertificate(admissionId: string, file: File): Promise<void> {
        const fileName = `${admissionId}.pdf`;  // ONLY pdf for now
        const currentUser = getAuth().currentUser;
        const storageRef = ref(getStorage(getAuth().app), `${FirebaseStoragePath.admissionDocuments}/${currentUser?.uid}/${fileName}`);

        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Get the download URL after the upload is complete
        const snapshot = await uploadTask;
        const downloadURL = await getDownloadURL(snapshot.ref);

        // Save file details to Firestore
        const updateDate = {
            documentLink: downloadURL.toString(),
            updatedDate: Timestamp.now()
        } as FirmAdmissionDetails.UpdateAdmissionDocumentDetails;

        // Use the user's UID as the document ID in Firestore
        const collRef = collection(getFirestore(getAuth().app), FirestoreCollection.admissionsDetails);
        const docRef = doc(collRef, admissionId);

        // Update document data in Firestore
        await updateDoc(docRef, updateDate);
    }
}
