import {getFunctions, httpsCallable, HttpsCallableResult} from 'firebase/functions';
import {CloudFunctions} from "../constants/CloudFunctions";
import {FirmAssociationDetails} from "../models/FirmAssociationDetails";
import {getAuth} from "firebase/auth";
import {addDoc, collection, getDocs, getFirestore, limit, query, Timestamp, where} from "firebase/firestore";
import {FirestoreCollection} from "../constants/FirestoreCollection";

export default class AssociationService {
    private static instance: AssociationService | null = null;

    static getInstance(): AssociationService {
        if (AssociationService.instance === null) {
            AssociationService.instance = new AssociationService()
        }

        return this.instance as AssociationService;
    }

    async getAllFirmAssociations(memberNumber: number): Promise<FirmAssociationDetails.FirmAssociationMmsAndGS[]> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.association);
        const funcRef = callable({memberNumber});

        return funcRef.then(res => {
            const responseModel = res.data as FirmAssociationDetails.AssociationResponseModel;
            return responseModel.data as FirmAssociationDetails.FirmAssociationMmsAndGS[];
        }).catch(error => {
            return [];
        })
    }

    async add(addDetail: FirmAssociationDetails.AssociationDetailsModel): Promise<FirmAssociationDetails.AssociationDetailsModel> {
        const currentUser = getAuth().currentUser;

        const detail = {
            ...addDetail,
            userId: currentUser?.uid,
            endStatus: "AwaitingAdmin",
            updatedDate: Timestamp.now(),
        } as FirmAssociationDetails.AssociationDetailsModel;

        const colRef = collection(getFirestore(getAuth().app), FirestoreCollection.associationsDetails);
        const docRef = await addDoc(colRef, detail);

        detail.associationId = docRef.id;
        return detail;
    }

    getAssociationByMmsNumber = async (lpcId: number): Promise<FirmAssociationDetails.AssociationDetailsModel | null> => {

        const activeQuery = query(collection(getFirestore(getAuth().app), FirestoreCollection.associationsDetails),
            where("userId", "==", getAuth().currentUser?.uid),
            where("lpcId", "==", lpcId),
            limit(1)
        );

        const querySnapshot = await getDocs(activeQuery);
        if (querySnapshot.empty) {
            return null;
        }

        return querySnapshot.docs[0].data() as FirmAssociationDetails.AssociationDetailsModel;
    }

    async getFirmDetails(firmNumber: number): Promise<FirmAssociationDetails.FirmDetails | null> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.firmDetails);
        const funcRef = callable({firmNumber});  // { firmNumber: 3}

        return funcRef
            .then((res) => {
                const responseModel = res.data as FirmAssociationDetails.FirmDetailsResponseModel;
                return responseModel.data || null;
            })
            .catch((error) => {
                return null;
            });
    }

    async submitFirmDetails(firmDetails: FirmAssociationDetails.FirmDetails): Promise<{
        success: boolean;
        error?: string
    }> {
        try {
            const callable = httpsCallable(getFunctions(), CloudFunctions.addfirmassociation);
            const funcRef = callable(firmDetails);

            const response = (await funcRef) as HttpsCallableResult<{ success: boolean; error?: string }>;
            return response.data;
        } catch (error) {
            return {success: false, error: 'An unexpected error occurred.'};
        }
    }
}
    
