import {useCallback, useEffect, useState} from 'react';
import './Payment.css';
import YocoPayment from "./YocoPayment";
import {PaymentDetails} from "../../models/PaymentDetails";
import InlineLoading from "../loading/InlineLoading";
import {AppConstants} from "../../constants/AppConstants";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {AppRoutes} from "../../constants/AppRoutes";
import PaymentService from "../../services/PaymentService";
import {CustomerDetails} from '../../models/CustomerDetails';
import {useSelector} from 'react-redux';
import {toast} from "react-toastify";
import {ToastOptionsConst} from "../../constants/ToastOptionsConst";

const paymentService = PaymentService.getInstance();
const Payment = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [paymentDetail, setPaymentDetail] = useState<PaymentDetails.Payment | null>(null);
    const [redirectUrl, setRedirectUrl] = useState<string>("");
    const [paymentStep, setPaymentStep] = useState<PaymentDetails.PaymentStep>("Checkout");
    const [errorMessage, setErrorMessage] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const paymentNavigationData = location.state as PaymentDetails.Payment;
    const [queryParameters, setQueryParameter] = useSearchParams();
    const {user}: { user: CustomerDetails.CustomerPersonalDetails } = useSelector((state: any) => state.user);

    const getPaymentDetailById = useCallback(async () => {
        const paymentIdParam = queryParameters.get("paymentId");
        if (!paymentIdParam) {
            setPaymentStep("ShowError");
            setErrorMessage(AppConstants.CannotFindPayment);
            setIsLoading(false);
            return;
        }

        await paymentService.getPaymentDetailById(paymentIdParam).then(data => {
            setPaymentDetail(data);
            setIsLoading(false);
            if (data === null) {
                setPaymentStep("ShowError")
                setErrorMessage(AppConstants.CannotFindPayment);
            }
        }).catch(err => {
            ErrorMsg();
            setIsLoading(false);
            setPaymentStep("ShowError");
            setErrorMessage(AppConstants.DefaultErrorMessage);
        })
    }, [queryParameters])

    const getOrCreatePaymentByInvoiceNumber = useCallback(async () => {
        try {
            let data = await paymentService.getPaymentDetailByInvoiceNumber(paymentNavigationData.invoiceNumber);
            if (!data) {
                // ELSE create new payment details in firestore ONLY if the data is null
                paymentNavigationData.province = user.province;  // append province
                data = await paymentService.loadMmsInvoiceToPayment(paymentNavigationData);
            }

            setPaymentDetail(data);
            setQueryParameter({paymentId: data.paymentId}); // update url params
            setIsLoading(false);
        } catch {
            ErrorMsg();
            setIsLoading(false);
            setPaymentStep("ShowError");
            setErrorMessage(AppConstants.DefaultErrorMessage);
        }
    }, [paymentNavigationData, setQueryParameter, user.province])

    const ErrorMsg = (msg?: string) => {
        toast.error(
            msg ?? AppConstants.DefaultErrorMessage,
            ToastOptionsConst.error
        );
    };

    // Create new payment details on firestore and MMS
    const getOrAddIsNewPaymentDetail = useCallback(async () => {
        const categoryParam = queryParameters.get("category");
        const categoryRefIdParam = queryParameters.get("categoryRefId");
        const isNewParam = queryParameters.get("isNew");

        if (!categoryParam || !categoryRefIdParam) {
            setPaymentStep("ShowError");
            setErrorMessage(AppConstants.DefaultErrorMessage);
            setIsLoading(false);
            return;
        }

        try {
            // GET
            if (isNewParam === "false") {
                const data = await paymentService.getPaymentDetailByCategoryRefId(categoryParam as PaymentDetails.PaymentCategory, categoryRefIdParam);
                setIsLoading(false);
                if (data === null) {
                    setPaymentStep("ShowError")
                    setErrorMessage(AppConstants.CannotFindPayment);
                    return;
                }

                setPaymentDetail(data);
                setQueryParameter({paymentId: data.paymentId}); // update url params
                return;
            }

            // ADD
            if (isNewParam === "true") {
                const addData = {
                    category: categoryParam,
                    categoryRefId: categoryRefIdParam,
                    memberNumber: user.memberNumber,
                    province: user.province,  // append province
                } as PaymentDetails.Payment;
                const paymentRes = await paymentService.add(addData, user.memberNumber);
                setPaymentDetail(paymentRes);
                queryParameters.delete("category")
                setIsLoading(false);

                // update url params
                navigate(AppRoutes.payment + "?paymentId=" + paymentRes.paymentId, {replace: true});
            }
        } catch (ex) {
            setPaymentStep("ShowError");
            setErrorMessage(AppConstants.DefaultErrorMessage);
            setIsLoading(false);
        }
    }, [navigate, queryParameters, user, setQueryParameter])

    const initializePaymentPage = useCallback(async () => {
        setIsLoading(true);
        // OPTION 1 - if there's data passed from another page via the router, then use that data instead of fetching from firestore
        if (paymentNavigationData) {
            if (paymentNavigationData.paymentId && (queryParameters.get("paymentId") as string).length > 0) {
                setPaymentDetail(paymentNavigationData);
                setIsLoading(false);
                return;
            }

            // OPTION 2 - if the payment type is coming from MMS directly like paymentId=undefined
            getOrCreatePaymentByInvoiceNumber();
            return;
        } else if (queryParameters.get("isNew") === "true" || queryParameters.get("isNew") === "false") { // by Category and CategoryRefId
            // OPTION 3 - if isNew parameter is present and TRUE then create new payment on MMS and firestore
            getOrAddIsNewPaymentDetail();
        } else {
            // OPTION 5 - ELSE get by provide PaymentId, this usual happens on a refresh
            getPaymentDetailById();
        }
    }, [paymentNavigationData, queryParameters, getOrAddIsNewPaymentDetail, getPaymentDetailById, getOrCreatePaymentByInvoiceNumber]);

    useEffect(() => {
        initializePaymentPage()
    }, [initializePaymentPage])

    useEffect(() => {
        window.document.addEventListener(AppConstants.OnPaymentNavigateEvent, () => {
            if(paymentDetail?.category === "GS"){
                navigate(AppRoutes.goodStanding);
            }else {
                navigate(AppRoutes.accountAndBilling);
            }
        }, false)
    }, [navigate, paymentDetail?.category])

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">

                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                <div className="invoice-payment-page">
                                    <div className="inv-title-wrap">
                                        <div className="good-standing d-flex align-items-center">
                                            <img className="m-2" src="/assets/icons/dollar-sign.png"
                                                 alt='Pay Invoice icon'/>
                                            <h2 className="color-main">Pay Invoice</h2>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col-12 col-md-12">
                                            {(paymentStep === "Loading" || isLoading) && <InlineLoading/>}
                                            {paymentStep === "Checkout" && paymentDetail &&
                                                <YocoPayment payment={paymentDetail} setRedirectUrl={setRedirectUrl}
                                                             isLoading={isLoading} setPaymentStep={setPaymentStep}
                                                             setIsLoading={setIsLoading}/>}

                                            {paymentStep === "Redirect" && redirectUrl &&
                                                <YocoRedirectIframe redirectUrl={redirectUrl}
                                                                    setPaymentStep={setPaymentStep}
                                                                    setIsLoading={setIsLoading}/>}

                                            {paymentStep === "ShowError" &&
                                                <div>
                                                    <hr/>
                                                    <h4>{errorMessage ?? AppConstants.DefaultErrorMessage}</h4></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Payment;

interface YocoRedirectIframeProps {
    redirectUrl: string,
    setPaymentStep: (value: PaymentDetails.PaymentStep) => void,
    setIsLoading: (value: boolean) => void,
}

const YocoRedirectIframe = ({redirectUrl, setPaymentStep, setIsLoading}: YocoRedirectIframeProps) => {
    const iframeLoaded = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 0)
    }

    return (
        <iframe className="payment-iframe" src={redirectUrl} onLoad={iframeLoaded}
                title="Yoco checkout Iframe" id="yoco-payment-iframe"></iframe>
    )
}
