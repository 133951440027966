import {CertificateDetails} from "../models/CertificateDetails";
import {getFunctions, httpsCallable} from "firebase/functions";
import {CloudFunctions} from "../constants/CloudFunctions";
import {getAuth} from "firebase/auth";

export default class CertificateService {
    private static instance: CertificateService | null = null;

    static getInstance(): CertificateService {
        if (CertificateService.instance === null) {
            CertificateService.instance = new CertificateService();
        }

        return this.instance as CertificateService;
    }

    async getCertificateDetail(): Promise<CertificateDetails.CertificateDetail | null> {
        try {
            const mockData: CertificateDetails.CertificateDetail = {
                certificateNumber: '013-656-7890',
                status: 'Active',
                issueDate: '10/10/2022',
                expirationDate: '3/01/2028',
            };
            return mockData;
        } catch (error) {
            console.error('An error occurred:', error);
            return null;
        }
    }

    async downloadCertificate(requestModel: CertificateDetails.CertificateGeneratorRequestModel): Promise<Blob> {
        const authApp = getAuth().app;
        const appName = authApp.options.projectId
        const region = getFunctions(authApp).region;
        const reqUrl = `https://${region}-${appName}.cloudfunctions.net/${CloudFunctions.certificateGenerator}`;
        return fetch(reqUrl, {
            method: 'POST',
            body: JSON.stringify(requestModel),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAuth()?.currentUser?.getIdToken()}`
            },
        }).then(response => response.blob());
    }

    async verifyCertificateByCertificateRefId(request: CertificateDetails.VerifyCertificateRequestModel): Promise<CertificateDetails.VerifyCertificateModel | null> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.verifyCertificate);
        const funcRef = callable(request);

        return funcRef.then(res => {
            const responseModel = res.data as CertificateDetails.VerifyCertificateResponseModel;
            return responseModel.data as CertificateDetails.VerifyCertificateModel;
        }).catch(error => {
            return null;
        })
    }
}
