import {useCallback, useEffect, useState} from 'react';
import "./Admissions.css";
import AdmissionService from "../../services/AdmissionServices";
import {FirmAdmissionDetails} from "../../models/AdmissionDetails";
import {AppConstants} from "../../constants/AppConstants";
import {ToastOptionsConst} from "../../constants/ToastOptionsConst";
import {toast} from "react-toastify";
import Loading from "../loading/Loading";
import {useSelector} from "react-redux";
import {format} from "date-fns";

const firmService = AdmissionService.getInstance();

export default function AdmissionList() {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [firmDataList, setFirmDataList] = useState<
        FirmAdmissionDetails.AdmissionDetail[]
    >([]);
    const {user} = useSelector((state: any) => state.user);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        await firmService
            .getAllFirmAdmissions(user.memberNumber)
            .then((allFirmData) => {
                setFirmDataList(allFirmData);
                setIsLoading(false);
            })
            .catch(() => {
                ErrorMsg(AppConstants.DefaultErrorMessage);
                setIsLoading(false);
            });
    }, [user]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // const addNewAdmission = () => {
    //     navigate(AppRoutes.admissionDetails);
    // }

    const ErrorMsg = (msg: string) => {
        toast.error(
            msg ?? AppConstants.DefaultErrorMessage,
            ToastOptionsConst.error
        );
    };

    if (isLoading) return <Loading/>;

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">

                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                {/* content start from here */}

                                <h1 className="lpc-page-heading mt-4">Admissions
                                    {/*<button type="button"*/}
                                    {/*        className="btn btn-lpc mx-4"*/}
                                    {/*        onClick={addNewAdmission}>Add New</button>*/}
                                </h1>
                                <br/>
                                <div className="table-border-wrap">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr className="headers">
                                            <th>Practioner Number</th>
                                            <th>Admission</th>
                                            <th>Admission Type</th>
                                            <th>Date</th>
                                            <th>Province</th>
                                            <th>Case No</th>
                                            <th>Current Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {firmDataList.length > 0 ? (
                                            firmDataList.map((detail, index) => (
                                                <tr key={index}>
                                                    <td>{detail.memberNumber}</td>
                                                    <td>{detail.admission}</td>
                                                    <td>{detail.admissionType}</td>
                                                    <td>{format(new Date(detail.date), "yyyy-MM-dd")}</td>
                                                    <td>{detail.province}</td>
                                                    <td>{detail.caseNo}</td>
                                                    <td>{detail.currentStatus}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={5}>No record found!</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                {/* content end here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
