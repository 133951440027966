import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { AppRoutes } from "../../constants/AppRoutes";
import { setLogout } from "../../slice";
import UserService from "../../services/UserService";
import "./MemberConfirmation.css";
import { CustomClaims } from "../../models/CustomClaims";
import Loading from "../loading/Loading";

const userService = UserService.getInstance();
export default function MemberConfirmation() {
    // if this is done already, then redirect to DASHBOARD
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [memberConfirmed] = useState<boolean>();
    const [isLoading] = useState<boolean>();
    const userState = useSelector((state: any) => state.user);

    // if this is done already, then redirect to DASHBOARD
    useEffect(() => {
        const currentUser = getAuth().currentUser;
        // check if user is logged in
        if (!userState.isLoggedIn || !currentUser) {
            navigate(AppRoutes.login, { replace: true });
            return;
        }

        checkIfEmailIsVerifiedAndAMember();
    })

    const checkIfEmailIsVerifiedAndAMember = async () => { // Check if email is NOT verified AND no claim
        const currentUser = getAuth().currentUser;
        // Check if email is NOT verified
        if (!currentUser?.emailVerified) { // false
            return;
        }

        const idToken = await currentUser?.getIdTokenResult();
        if (idToken) {
            const lpcMemberClaims = idToken?.claims as unknown as CustomClaims.LpcMemberCustomClaims;
            if (currentUser?.emailVerified && lpcMemberClaims.lpcMember) {
                navigate(AppRoutes.goodStanding, { replace: true });
                return;
            }
        }
    }

    const onGoToLoginPage = async () => {
        await userService.signOut();
        dispatch(setLogout());
        navigate(AppRoutes.login, { replace: true });
    }

    return (
        <div> {isLoading ? <Loading message={"Confirming your details with LPC, please wait..."} /> :
            <div>{memberConfirmed ? <CompletedRegistration onGoToLoginPage={onGoToLoginPage} /> :
                <div className="auth-form">
                    <div className="container h-100">
                        <div className="d-flex flex-column align-items-center justify-content-center h-100">
                            <div className="form-inner">
                                <div className="auth-logo">
                                    <img src="/images/lpc-logo.jpg" alt="" />
                                </div>
                                <br />
                                <br />
                                <div className="payment-confirmation-wrap">
                                    <img src="/assets/icons/member-search-red.png" alt=""
                                        style={{ height: '100px' }} />
                                    <br />
                                    <h4>Details Confirmation FAILED</h4>                                    
                                        <p>Something went wrong, please contact LPC Admin and we will be able to
                                            assist you from there </p>
                                    <div className="mb-3 mt-3">
                                        <button onClick={onGoToLoginPage} type="submit"
                                            className="btn btn-lpc-outline text-uppercase">
                                            BACK TO LOGIN
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}

const CompletedRegistration = ({ onGoToLoginPage }: { onGoToLoginPage: () => void }) => {
    useEffect(() => {
        setTimeout(() => {
            onGoToLoginPage()
        }, 3000)
    }, [onGoToLoginPage])

    return (
        <div className="completed-registration-container">
            <svg width="350" height="350">
                <circle fill="none" stroke="#ffffff" strokeWidth="20" cx="200" cy="200" r="100" className="circle"
                    strokeLinecap="round" transform="rotate(-90 200 200) " />
                <polyline fill="none" stroke="#ffffff" strokeWidth="24" points="140,214 180,260 260,160"
                    strokeLinecap="round" strokeLinejoin="round" className="tick" />
            </svg>
            <h1 className="completed-registration-title">Registration Completed!</h1>
            <div className="mb-3 mt-3">
                <button onClick={onGoToLoginPage} type="submit"
                    className="btn btn-lpc-outline text-uppercase btn-white-text">
                    BACK TO LOGIN
                </button>
            </div>
        </div>
    )
}
