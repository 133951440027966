import {AppRoutes} from "../../constants/AppRoutes";

export interface RouteListConstModel {
    icon: string,
    pathname: string,
    title: string
}

export const RouteListConst: Array<RouteListConstModel> = [
    {
        icon: "/assets/icons/check.png",
        pathname: AppRoutes.goodStanding,
        title: "Good Standing"
    },
    {
        icon: "/assets/icons/application.png",
        pathname: AppRoutes.applications,
        title: "Applications"
    },{
        icon: "/assets/icons/user.png",
        pathname: AppRoutes.profile,
        title: "Profile"
    }, {
        icon: "/assets/icons/admission.png",
        pathname: AppRoutes.admission,
        title: "Admissions"
    }, {
        icon: "/assets/icons/firm-association.png",
        pathname: AppRoutes.associationList,
        title: "Firm Associations"
    }, {
        icon: "/assets/icons/invoice.png",
        pathname: AppRoutes.accountAndBilling,
        title: "Account & Billing"
    }
]