export const CloudFunctions = {
    association: "association",
    admission: "admission",
    applications: "applications",
    memberConfirmation: "memberconfirmation",
    memberRegistration: "memberregistration",
    memberForgotPassword: "memberforgotpassword",
    memberDetails: "memberdetails",
    paymentCheckout: "paymentcheckout",
    businessRule: "businessrule",
    sendemail: "sendemail",
    invoiceList: "invoicelist",
    certificateGenerator: "certificategenerator",
    generateGsInvoice: "generategsinvoice",
    firmDetails: 'firmdetails', 
    addfirmassociation: 'addfirmassociation',
    verifyCertificate: 'verifycertificate',
};
