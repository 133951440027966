export const AppRoutes = {
    login : "/login",
    register: '/register',
    forgotPassword: '/forget-password',
    accountAndBilling: '/account-and-billing',
    profile: '/profile',
    paymentConfirmation: '/payment-confirmation',
    admission: '/admission',
    goodStanding: '/good-standing',
    transactions: '/account-and-billing',
    certificates: '/certificates',
    applications: '/applications',
    applicationDetails: '/application/details',
    editApplication: '/edit-application/:docid',
    payment: '/payment',
    associationList: '/associationlist',
    admissionDetails: '/admission/details',
    associationDetails: '/association/details',
    memberConfirmation : "/memberconfirmation",
    paymentFailed : "/payment-failed",
    paymentSuccess : "/payment-success",
    help : "/help",

    // public pages
    verifyCertificate: '/public/verify-certificate',
};
