import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { FaBars, FaTimes } from "react-icons/fa";
import "./Topbar.css"
import { AppRoutes } from "../../../constants/AppRoutes"
import { useDispatch } from 'react-redux';
import { setLogout } from '../../../slice';
import UserService from "../../../services/UserService";
import {RouteListConst, RouteListConstModel} from "../routelist.const";

const userService = UserService.getInstance();
const routList: Array<RouteListConstModel> = RouteListConst;

const Topbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user);
    const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false);


    const toggleMobileNav = () => {
        setMobileNavOpen(!mobileNavOpen);
    };

    const closeMobileNav = () => {
        setMobileNavOpen(false);
    };

    const logOut = async () => {
        await userService.signOut();
        dispatch(setLogout());
        navigate('/login');
    }

    const getInitials = () => {
        const name = user.user.name || '';
        const surname = user.user.surname || '';
        return `${name.charAt(0)}${surname.charAt(0)}`;
    }

    return (
        <div className="profile-header">
            <div className="large-screen-profile py-3 px-5">
                <div className="profile">
                    <div className="user-profile">
                        <div className="user-info">
                            <div id="test1">
                                <h6 className="member-names">{`${user.user.name} ${user.user.surname}`}</h6>
                            </div>
                            <p className='member-number'>Practioner Number: {user.user.memberNumber}</p>
                            <hr className='horizontal-line' />
                        </div>
                        <Link className="member-initials-container" to={AppRoutes.profile}>
                            {getInitials()}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="mobile-nav">
                <div className="bars" onClick={toggleMobileNav}>
                    <FaBars className='btn-bars' />
                </div>
                <div className="profile">
                    <div className="user-profile">
                        <div className="user-info">
                            <div id="test1">
                                <h6 className="member-names">{`${user.user.name} ${user.user.surname}`}</h6>
                            </div>
                            <p className='member-number'>Practioner Number: {user.user.memberNumber}</p>
                            <hr className='horizontal-line' />
                        </div>
                        <Link className="member-initials-container" to={AppRoutes.profile}>
                            {getInitials()}
                        </Link>
                    </div>
                </div>
            </div>

            {/* Mobile Navigation Menu */}
            {mobileNavOpen && (
                <div className="mobile-nav-wrapper">
                    <div className="mobile-nav-menu">
                        <div className="link-wrapper">
                            <div className="times" onClick={toggleMobileNav}>
                                <FaTimes className="btn-times" />
                                <a href="/public#" className="app-brand-link">
                                    <span className="app-brand-logo demo">
                                        <img src="/images/lpc-logo.jpg" alt="" style={{ height: '40px' }} />
                                    </span>
                                </a>
                            </div>
                            <hr />
                            <ul className="links">
                                {routList.map((link) => (
                                    <Link to={link.pathname} key={link.title} onClick={closeMobileNav}>
                                        <li className="list">
                                            <span>{link.title}</span>
                                        </li>
                                    </Link>
                                ))}

                                <li className="logout">
                                    <div className="logout-btn" onClick={logOut}>
                                        <div>Logout</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Topbar
