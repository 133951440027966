export const AppSettings = {
    portalBaseUrl: process.env.REACT_APP_PORTAL_BASE_URL,

    // Member - Email proxy domain
    emailProxyDomain : "portal.lpc.org.za",

    // yoco
    yocoScripts : "https://js.yoco.com/sdk/v1/yoco-sdk-web.js",
    currency: "ZAR"
}
