import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import UserService from "../../services/UserService";
import {CustomerDetails} from "../../models/CustomerDetails";
import {AppConstants} from "../../constants/AppConstants";
import {Link} from 'react-router-dom';
import {AppRoutes} from "../../constants/AppRoutes";
import {ToastOptionsConst} from '../../constants/ToastOptionsConst';
import './ForgotPassword.css';

const userService = UserService.getInstance();

export default function ForgotPassword() {
    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'LPC - Login';
        if (user.isLoggedIn) {
            navigate("/");
        }
    }, [user, navigate]);

    const [isLoading, setLoading] = useState(false);

    const [formData, setFormData] = useState<CustomerDetails.SignInCredentials>({
        identityNumber: "",
        password: ""
    } as CustomerDetails.SignInCredentials);

    const handleInputChange = (event: any) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        // Calling UserService to send a password reset email
        await userService.sendPasswordResetEmail({identityNumber: formData.identityNumber} as CustomerDetails.MemberForgotPasswordRequestModel)
            .then((x) => {
                if (x.isSuccess) {
                    setLoading(false);
                    SuccessMsg("Password reset email sent. Check your inbox.");
                } else {
                    setLoading(false);
                    ErrorMsg(x.message || AppConstants.DefaultErrorMessage);
                }
            })
            .catch(err => {
                setLoading(false);
                // Assuming err.message contains the error message
                ErrorMsg(err.message || AppConstants.DefaultErrorMessage);
            });
    };

    return (
        <div className="auth-form">
            <div className="container h-100">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="form-inner">

                        <div className="auth-logo">
                            <img src="/images/lpc-logo.jpg" alt=""/>
                        </div>
                        <h4>Forgot Password</h4>
                        <br/>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input id="identityNumber" type="text" className="form-control" name="identityNumber"
                                       placeholder="ID Number"
                                       maxLength={13}
                                       value={formData.identityNumber}
                                       onChange={handleInputChange}
                                       required/>
                            </div>

                            <p className='mb-3 forgot-password-note'>A password reset link will be sent to the email address registered with LPC.</p>
                            <div className="text-center">
                                <button type="submit" className="btn btn-lpc text-uppercase">
                                    {isLoading ? "Submitting..." : "Send Email to Inbox"}
                                </button>
                            </div>
                            <div className="mb-3 mt-3">
                                <Link className="color-main text-uppercase" to={AppRoutes.login}>
                                    GO TO LOGIN
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

