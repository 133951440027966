import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    isLoggedIn: false,
    user: {},
    token: ''
}


const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsLoggedIn: (state) => {
            state.isLoggedIn = true;
        },
        setLogin: (state, action) => {
            state.user = action.payload;
        },
        setAuthorizationToken: (state, action) => {
            state.token = action.payload;
        },
        setLogout: (state) => {
            state.user = {};
            state.token = '';
            state.isLoggedIn = false;
        }
    }
});

export const { setIsLoggedIn, setLogin, setAuthorizationToken, setLogout } = userSlice.actions;


export default userSlice.reducer;