import React, {useCallback, useEffect, useState} from 'react'
import "./VerifyCertificate.css";
import Loading from "../../components/loading/Loading";
import {Table} from "react-bootstrap";
import CertificateService from "../../services/CertificatesService";
import {CertificateDetails} from "../../models/CertificateDetails";
import {format} from "date-fns";
import {Timestamp} from "firebase/firestore";
import {useSearchParams} from "react-router-dom";

const certificateService = CertificateService.getInstance();
export default function VerifyCertificate() {
    const [queryParameters] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [verifyCertificateModel, setVerifyCertificateModel] = useState<CertificateDetails.VerifyCertificateModel | null>(null);

    const verifyCertificate = useCallback(async () => {
        // get a param
        const certificateRefIdParam = queryParameters.get("certificateRefId");
        if (!certificateRefIdParam) {
            setVerifyCertificateModel({
                certificateValid: false,
                certificateRefId: certificateRefIdParam
            } as CertificateDetails.VerifyCertificateModel);
            setIsLoading(false);
            return;
        }

        // call an API to confirm this
        const response = await certificateService.verifyCertificateByCertificateRefId({certificateRefId: certificateRefIdParam});
        setVerifyCertificateModel(response);
        setIsLoading(false);
    }, [queryParameters])

    useEffect(() => {
        verifyCertificate()
    }, [verifyCertificate])

    return (
        <div> {isLoading || (verifyCertificateModel == null) ?
            <Loading message={"Confirming certificate status with LPC, please wait..."}/> :
            <div
                className={"verify-certificate-container " + (verifyCertificateModel?.certificateValid ? "verify-certificate-container-valid" : "")}>
                {verifyCertificateModel?.certificateValid ? <div>
                        {verifyCertificateModel && <CertificateTable certificateDetails={verifyCertificateModel}/>}
                        <svg width="350" height="350">
                            <circle fill="none" stroke="#ffffff" strokeWidth="20" cx="200" cy="200" r="100"
                                    className="circle"
                                    strokeLinecap="round" transform="rotate(-90 200 200) "/>
                            <polyline fill="none" stroke="#ffffff" strokeWidth="24" points="140,214 180,260 260,160"
                                      strokeLinecap="round" strokeLinejoin="round" className="tick"/>
                        </svg>
                        <h1 className="certificate-valid">Certificate is Valid!</h1>
                    </div> :
                    <> {verifyCertificateModel && <div>
                        {verifyCertificateModel && <CertificateTable certificateDetails={verifyCertificateModel}/>}
                        <svg width="350" height="350">
                            <circle fill="none" stroke="red" strokeWidth="20" cx="200" cy="200" r="100"
                                    className="circle"
                                    strokeLinecap="round" transform="rotate(-90 200 200)"/>
                            <line x1="150" y1="150" x2="250" y2="250" stroke="red" strokeWidth="24"
                                  strokeLinecap="round"/>
                            <line x1="250" y1="150" x2="150" y2="250" stroke="red" strokeWidth="24"
                                  strokeLinecap="round"/>
                        </svg>
                        <h1 className="certificate-not-valid">Certificate is NOT Valid</h1>
                    </div>}</>}
            </div>}
        </div>
    )
}

interface CertificateTableProps {
    certificateDetails: CertificateDetails.VerifyCertificateModel;
}

export function CertificateTable({certificateDetails}: CertificateTableProps) {
    return (
        <>
            <div className="lpc-logo-container mb-3">
                <img src="/images/lpc-logo-no-bg.png" alt=""/>
            </div>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Full Name</th>
                    <th>Certificate Number</th>
                    <th>Date Last Updated</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{certificateDetails.firstName} {certificateDetails.surname}</td>
                    <td>#{certificateDetails.certificateRefId}</td>
                    <td> {certificateDetails.certificateUpdatedDate &&
                        format(
                            new Date((new Timestamp(certificateDetails.certificateUpdatedDate._seconds, certificateDetails.certificateUpdatedDate._nanoseconds)).toDate()),
                            "yyyy-MM-dd HH:mm"
                        )}
                    </td>
                </tr>
                </tbody>
            </Table>
        </>
    );
}
