import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {AppRoutes} from "../../constants/AppRoutes";
import {AppConstants} from "../../constants/AppConstants";
import InlineLoading from '../loading/InlineLoading';
import PaymentService from '../../services/PaymentService';

const paymentService = PaymentService.getInstance();
export default function PaymentSuccess() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [parentQueryParameters, setParentQueryParameters] = useSearchParams(window.parent.location.search);
    const [childQueryParameters, setChildQueryParameters] = useSearchParams(window.location.search);

    const updatePaymentDetails = useCallback(async () => {
        setIsLoading(true);
        setParentQueryParameters(window.parent.location.search);
        setChildQueryParameters(window.location.search);
        const paymentIdParam = parentQueryParameters.get("paymentId");
        if (paymentIdParam) {
            await paymentService.updatePaymentStatusToVerifying(paymentIdParam);
        }
        setIsLoading(false);
    }, [parentQueryParameters, setParentQueryParameters, setChildQueryParameters])

    useEffect(() => {
        updatePaymentDetails();
    }, [updatePaymentDetails])

    const goToDashboard = () => {
        if (pageLoadedInIFrame()) {
            // if its in iframe, emit an event
            const event = new CustomEvent(AppConstants.OnPaymentNavigateEvent);
            window.parent.document.dispatchEvent(event);
        } else if (childQueryParameters?.get("category") === "GS") {
            navigate(AppRoutes.goodStanding)
        } else {
            // else just navigate
            navigate(AppRoutes.accountAndBilling)
        }
    }

    const pageLoadedInIFrame = () => {
        return window.location !== window.parent.location;
    }

    return (
        <div className={pageLoadedInIFrame() ? "" : "auth-form"}>
            <div className="container h-100">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                    <div className="form-inner">
                        <div className="auth-logo">
                            <img src="/images/lpc-logo.jpg" alt=""/>
                        </div>
                        <div className="payment-confirmation-wrap mt-4">
                            <img src="/assets/icons/check-confirm.png" alt="" style={{height: '50px'}}/>
                            <br/>
                            <h4>Payment successful!</h4>
                            <p>Successfully made a payment!</p>
                            {isLoading ? <InlineLoading/> :
                                <button onClick={goToDashboard} className="btn btn-lpc text-uppercase">Go Back
                                </button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
