import { SubmitHandler, useForm } from "react-hook-form";
import './Help.css';
import { ContactUsDetails } from "../../models/ContactUsDetails";
import { CommonModels } from "../../models/CommonModels";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ToastOptionsConst } from "../../constants/ToastOptionsConst";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import ContactUsService from "../../services/ContactUsService";
import { AppConstants } from "../../constants/AppConstants";
import { useSelector } from "react-redux";
import { CustomerDetails } from "../../models/CustomerDetails";
import UserService from "../../services/UserService";

const contactUsService = ContactUsService.getInstance();
const userService = UserService.getInstance();
const provinceList = CommonModels.ProvinceTypeMap;
const provinceKeyList = Object.keys(CommonModels.ProvinceTypeMap).filter(x => x !== "ALL");
export default function Help() {
    const navigate = useNavigate();
    const [contactList, setContactList] = useState<Array<ContactUsDetails.AdmincContactsInfo>>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { user }: { user: CustomerDetails.CustomerPersonalDetails } = useSelector((state: any) => state.user);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm<ContactUsDetails.ContactUsRequest>();

    const fetchContactList = useCallback((selectedProvince: CommonModels.ProvinceType) => {  
        // reset recipientContact
        setIsLoading(true);
        setValue("recipienctEmail", '');     
        setContactList([]);

        contactUsService.getAdminContactDetailByProvince(selectedProvince).then(x => {
            if (x == null) return;

            setContactList(x.list);
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
            ErrorMsg();
        })
    }, [setValue])

    useEffect(() => {
        fetchContactList("EasternCape");
    }, [fetchContactList])

    const handleProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedProvince = event.target.value as CommonModels.ProvinceType;
        fetchContactList(selectedProvince);
    };

    const onSubmit: SubmitHandler<ContactUsDetails.ContactUsRequest> = async (data: ContactUsDetails.ContactUsRequest) => {
        data.subject = "Enqury from " + user.name + " " + user.surname + " with practioner number " + user.memberNumber;
        data.senderEmail = userService.getProxyEmailAddress(user.identityNumber);
        contactUsService.sendEmailToAdmin(data).then(x => {
            SuccessMsg("Successfully sent email to support!");
            reset();
            setTimeout(() => { navigate(AppRoutes.goodStanding) }, 3000);
        }).catch(() => {
            ErrorMsg();
        })
    }

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg?: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className="d-flex align-items-center">
                                        <div className="text-start admission-details-title">
                                            <h2 className="lpc-page-heading">Contact Us</h2>
                                        </div>
                                    </div>
                                </div>
                                <form method="post" className="simple-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="d-flex flex-input mb-5">
                                        <div className="flex-input-right w-50 lpc-select-wrapper">
                                            <div className={`${errors.province ? 'has-error' : ''} `}>
                                                <select
                                                    {...register("province", { required: true, onChange: handleProvinceChange })} id="province" className="lpc-select-input">
                                                    {provinceKeyList.map(x => (<option key={x}
                                                        value={x}>{provinceList[x as CommonModels.ProvinceType]}</option>))}
                                                </select>
                                                {errors.province && <span>{errors.province.message}</span>}
                                            </div>
                                        </div>
                                        <div className="flex-input-right w-50 lpc-select-wrapper">
                                            <div className={`${errors.recipienctEmail ? 'has-error' : ''} `}>
                                                <select id="recipienctEmail" {...register("recipienctEmail", { required: true })} className="lpc-select-input">
                                                    {contactList?.map((x, index) => (<option key={index} value={x.emailAddress}>{x.description}</option>))}
                                                </select>
                                                {errors.recipienctEmail && <span>{errors.recipienctEmail.message}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group text-start">
                                        <textarea
                                            className="lpc-text-area"
                                            {...register("body", { required: true })}
                                            placeholder="Message"
                                            rows={5}
                                        ></textarea>
                                        {errors.body && <p className="lpc-error-msg">*All fiels are required</p>}
                                        <div className="mt-4">
                                            <button className="btn btn-lpc" type="submit" disabled={isLoading}>SEND TO SUPPORT
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

