import React from "react";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {setLogout} from '../../../slice';
import UserService from "../../../services/UserService";
import {AppRoutes} from '../../../constants/AppRoutes';

import './SideBar.css';
import {RouteListConst, RouteListConstModel} from "../routelist.const";

const userService = UserService.getInstance();
const routList: Array<RouteListConstModel> = RouteListConst;

export default function SideBar() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const logOut = async () => {
        await userService.signOut();
        dispatch(setLogout());
        navigate(AppRoutes.login);
    }

    return (
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <div className="app-brand demo mb-5 mt-3">
                <a href="/public#" className="app-brand-link">
                    <span className="app-brand-logo demo">
                        <img src="/images/lpc-logo.jpg" alt="" style={{height: '80px'}}/>
                    </span>
                </a>
                <div className="layout-menu-toggle menu-link text-large ms-autod-block d-xl-none">
                    <i className="fa fa-chevron-left align-middle"></i>
                </div>
            </div>
            <div className="menu-inner-shadow"></div>
            <ul className="menu-inner py-1">
                {routList.map((x) => (
                    <Link
                        to={x.pathname}
                        key={x.title}
                        className={"menu-item " + (x.pathname === location.pathname ? "active" : "")}
                    >
                        <li>
                            <div className="menu-link">
                                <img className="menu-icon" src={x.icon} alt={x.title}/>
                                <span>{x.title}</span>
                            </div>
                        </li>
                    </Link>
                ))}
                <li className="menu-item">
                    <div className="menu-link" style={{cursor: 'pointer'}} onClick={logOut}>
                        <img className="menu-icon" src="/assets/icons/logout.png" alt="logout"/>
                        <div style={{color: '#222'}}>Logout</div>
                    </div>
                </li>

                <Link className="menu-item" to="/help">
                    <li>
                        <div className="menu-link">
                            <img className="menu-icon" src="/assets/icons/info.png" alt="Help"/>
                            Help
                        </div>
                    </li>
                </Link>
            </ul>
        </aside>
    )
}
