import {useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import {AppRoutes} from "./constants/AppRoutes";
import {CustomClaims} from "./models/CustomClaims";
import {getAuth} from "firebase/auth";
import {useEffect, useState} from "react";
import Loading from "./components/loading/Loading";

interface Props {
    children: any
}

export default function ProtectedRout({children}: Props) {
    const user = useSelector((state: any) => state.user);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    let navigate = useNavigate();

    useEffect(() => {
        checkEmailVerificationAndClaims();
    })

    const checkEmailVerificationAndClaims = async () => {
        const currentUser = getAuth().currentUser;
        // check if user is logged in
        if (!user.isLoggedIn || !currentUser) {
            navigate(AppRoutes.login, {replace: true});
            return;
        }

        // Check if email is NOT verified
        if (!currentUser?.emailVerified) { // false
            return;
        }

        // LPCMember claim = true/false
        const idToken = await currentUser?.getIdTokenResult();
        if (idToken) {
            const lpcMemberClaims = idToken?.claims as unknown as CustomClaims.LpcMemberCustomClaims;
            if (!lpcMemberClaims.lpcMember || !lpcMemberClaims.memberNumber) { // false || undefined
                navigate(AppRoutes.memberConfirmation, {replace: true});
                return;
            }
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    return children;
};
