export namespace AppConstants {
    export const DefaultErrorMessage: string = "Oops! Something went wrong!";    
    export const CannotFindPayment: string = "Oops! We couldn't find any invoice matching given ID, please confirm or contact admin.";
    export const PasswordResetSentToInbox = "We’ve sent a password reset email to your inbox or spam";
    export const UserIsNotAnAdmin: string = "User is NOT an admin, contact your system admin";
    export const VerifyYourEmailAddress: string = "Please verify your email address. Kindly check your inbox.";
    export const OTPTimedOut: string = "OTP has timed out!";
    export const OTPIsInvalid: string = "OTP is invalid. Please enter correct code";
    export const BussinessRulesMustPass  = "Some required business rules are failing"
    export const AuthenticationErrorMap: AuthenticationErrorType = {
        "auth/user-not-found": "Email and Password combination not found",
        "auth/wrong-password": "Email and Password combination not found",
        "auth/invalid-login-credentials": "Email and Password combination not found",
        "auth/invalid-email": "Email address is invalid",
        "auth/email-already-in-use": "Email address already registered",
        "auth/weak-password": "Your PASSWORD is weak",
        "auth/second-factor-already-in-use": "Multi-factor already completed. Please login",
        "auth/invalid-verification-code": OTPIsInvalid,
        "auth/operation-not-allowed": DefaultErrorMessage,
    }

    export const AuthMFARequired = "auth/multi-factor-auth-required";  // Admin only

    export const InvalidPhoneNumber = "auth/invalid-phone-number";
    export const OnPaymentNavigateEvent = "OnPaymentNavigateEvent";

    type AuthenticationErrorType = Record<string, string>;

    export const DateTimeFormat = 'dd-MMM-yyyy HH:mm:ss';

    // Application active days
    export const ApplicationActiveDaysCount = 365;

    export const ffcApplyLink = 'https://ffc.fidfund.co.za/login/';
}
