import React, {useEffect, useState} from "react";
import "react-circular-progressbar/dist/styles.css";
import {ApplicationDetails} from "../../models/ApplicationDetails";
import ApplicationService from "../../services/ApplicationService";
import {useNavigate} from "react-router-dom";
import Loading from "../loading/Loading";
import {ToastOptionsConst} from "../../constants/ToastOptionsConst";
import {AppConstants} from "../../constants/AppConstants";
import {toast} from "react-toastify";
import {AppRoutes} from "../../constants/AppRoutes";
import {format, subDays} from "date-fns";
import {PaymentDetails} from "../../models/PaymentDetails";

const applicationService = ApplicationService.getInstance();

const Applications = () => {
    const [applicationList, setApplicationList] = useState<
        ApplicationDetails.ApplicationModel[]
    >([]);
    const [loading, setLoading] = useState(true);
    const [hideAddNewButton        , setHideAddNewButton] = useState(true);
    const navigate = useNavigate();

    // fetching applications
    useEffect(() => {
        applicationService
            .getApplicationListByUserId()
            .then((data) => {
                if (data) {
                    const sortedApplications = data.sort((a, b) => {
                        // Assuming updatedDate is a Firestore Timestamp, convert to JavaScript Date
                        const timestampA = a.updatedDate.toDate().getTime();
                        const timestampB = b.updatedDate.toDate().getTime();

                        // Sort in descending order (newest first)
                        return timestampB - timestampA;
                    });

                    setApplicationList(sortedApplications);

                    // hide add new button if we have an application with a pending payment in One Year
                    const oneYearAgoDate = subDays(new Date(), AppConstants.ApplicationActiveDaysCount);
                    const isApplicationWithPendingPaymentInOneYear= sortedApplications.some(x=>x.status === "NotPaid" && (x.updatedDate.toDate() >= oneYearAgoDate));
                    setHideAddNewButton(isApplicationWithPendingPaymentInOneYear);
                } else {
                    setApplicationList([]);
                    setHideAddNewButton(false);
                }
                setLoading(false);
            })
            .catch((error) => {
                ErrorMsg(AppConstants.DefaultErrorMessage);
                setLoading(false);
            });
    }, []); // Empty dependency array means this effect runs once on mount

    const ErrorMsg = (msg: string) => {
        toast.error(
            msg ?? AppConstants.DefaultErrorMessage,
            ToastOptionsConst.error
        );
    };

    if (loading) {
        return <Loading/>;
    }
    const addNewApplication = () => {
        navigate(AppRoutes.applicationDetails);
    };

    const payPendingPaymentNow = (appParam: ApplicationDetails.ApplicationModel) => {
        const paymentCategory = "GS" as PaymentDetails.PaymentCategory;
        navigate(AppRoutes.payment + "?isNew=false&category=" + paymentCategory + "&categoryRefId=" + appParam.applicationId);  // isNew = FALSE
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                {/* content start from here */}

                                <h1 className="lpc-page-heading mt-4">
                                    Applications{" "}
                                    <button
                                        type="button"
                                        className="btn btn-lpc mx-4"
                                        onClick={addNewApplication}
                                        hidden={hideAddNewButton}
                                    >
                                        Add New
                                    </button>
                                </h1>
                                <br/>
                                <div className="table-border-wrap">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr className="headers">
                                            <th>Application Number</th>
                                            <th>Date Updated</th>
                                            <th>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {applicationList?.map((app) => (
                                            <tr key={app.applicationId}>
                                                <td className="text-start">
                                                    {app.applicationNumber}
                                                </td>
                                                <td className="text-start">
                                                    {app.updatedDate &&
                                                        format(
                                                            new Date(app.updatedDate.toDate()),
                                                            "yyyy-MM-dd"
                                                        )}
                                                </td>
                                                <td className="" style={{width: "40%"}}>
                                                    {app.status === "NotPaid" ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-lpc mx-4"
                                                                onClick={() => payPendingPaymentNow(app)}>
                                                                Pay Now
                                                            </button>
                                                        ) :
                                                        <div
                                                            className="progress"
                                                            role="progressbar"
                                                            aria-label="Basic example"
                                                            aria-valuenow={0}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        >
                                                            {app.status === "Withdrawn" && (
                                                                <div className="progress-bar rejected-status">
                                                                    Withdrawn
                                                                </div>
                                                            )}
                                                            {app.status === "Active" && (
                                                                <div className="progress-bar approve-status">
                                                                    Active
                                                                </div>
                                                            )}
                                                        </div>}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                {/* content end here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Applications;
