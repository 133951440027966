import React from 'react';
import "./Loading.css";

interface Props {
    message?: string
}

export default function Loading({message}: Props) {
    return (
        <div className="lds-spinner-container">
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <br/>
            <div>
                {message ?? ""}
            </div>
        </div>
    )
}
