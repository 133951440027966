import React, {useCallback, useEffect, useState} from 'react';
import AssociationService from '../../services/AssociationService';
import {FirmAssociationDetails} from "../../models/FirmAssociationDetails";
import {toast} from "react-toastify";
import {AppConstants} from "../../constants/AppConstants";
import {ToastOptionsConst} from "../../constants/ToastOptionsConst";
import {useSelector} from "react-redux";
import Loading from "../loading/Loading";
import {format} from "date-fns";
import Modal from 'react-bootstrap/Modal';
import {SubmitHandler, useForm} from "react-hook-form";
import {Timestamp} from "firebase/firestore";
import {CustomerDetails} from "../../models/CustomerDetails";

const firmService = AssociationService.getInstance();
const AssociationList: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>();
    const [firmDataList, setFirmDataList] = useState<FirmAssociationDetails.FirmAssociationMmsAndGS[]>([]);
    const {user}: { user: CustomerDetails.CustomerPersonalDetails } = useSelector((state: any) => state.user);
    const fetchData = useCallback(async () => {
        const associationTrackingPromises: Array<Promise<FirmAssociationDetails.FirmAssociationMmsAndGS>> = [];
        setIsLoading(true)
        await firmService.getAllFirmAssociations(user.memberNumber).then(async allFirmData => {
            setFirmDataList(allFirmData);

            // iterate and find any docs from Association docs
            for (const firm of allFirmData) {
                associationTrackingPromises.push(firmService.getAssociationByMmsNumber(firm.lpcId).then(association => {
                    let firmMmsAndGS: FirmAssociationDetails.FirmAssociationMmsAndGS = {
                        ...firm,
                    } as FirmAssociationDetails.FirmAssociationMmsAndGS;

                    if (association) {
                        firmMmsAndGS = {...firmMmsAndGS, ...association} as FirmAssociationDetails.FirmAssociationMmsAndGS;
                    } else {
                        firmMmsAndGS.endStatus = 'NotApplicable';
                    }

                    return firmMmsAndGS
                }));
            }

            // update main list
            Promise.all(associationTrackingPromises).then(newList => {
                setFirmDataList(newList);
                setIsLoading(false)
            }).catch(() => {
                ErrorMsg(AppConstants.DefaultErrorMessage)
                setIsLoading(false)
            });
        }).catch(() => {
            ErrorMsg(AppConstants.DefaultErrorMessage)
            setIsLoading(false)
        });
    }, [user]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // const addNewAssociation = () => {
    //     navigate(AppRoutes.associationDetails);
    // }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }
    const filterAssociation = (firm: FirmAssociationDetails.MmsFirmAssociation) => {
        const newList = firmDataList.filter(x => x.lpcId !== firm.lpcId);
        setTimeout(() => {
            setFirmDataList(newList);
        }, 0)
    }
    if (isLoading) return <Loading/>;

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="content-wrapper">
                        <div className="d-flex align-items-stretch flex-grow-1 p-0">
                            <div className="container-xxl flex-grow-1 container-p-y px-5">
                                <h1 className="lpc-page-heading mt-4">Firm Associations
                                    {/*<button type="button"*/}
                                    {/*        className="btn btn-lpc mx-4 "*/}
                                    {/*        onClick={addNewAssociation}>Add*/}
                                    {/*    New</button>*/}
                                </h1>
                                <br/>
                                <div className="table-border-wrap">
                                    <table className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>Firm Number</th>
                                            <th>Firm Name</th>
                                            <th>Province</th>
                                            <th>Status</th>
                                            <th>StatusDesc</th>
                                            <th>Date Started</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {firmDataList.length > 0 ? (
                                            firmDataList.map((firmData) => (
                                                <tr key={firmData.lpcId}>
                                                    <td>{firmData.firmNumber}</td>
                                                    <td>{firmData.firmName}</td>
                                                    <td>{firmData.province}</td>
                                                    <td>{firmData.status}</td>
                                                    <td>{firmData.statusDesc}</td>
                                                    <td>{format(new Date(firmData.dateStarted), "yyyy-MM-dd")}</td>
                                                    <td>
                                                        {firmData.endStatus && firmData.endStatus === 'NotApplicable' ?
                                                            <EndAssociationModal firm={firmData}
                                                                                 user={user}
                                                                                 removeAssociationFromList={filterAssociation}/> : firmData.endStatus}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={5}>No record found!</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssociationList;

interface EndAssociationModalProps {
    firm: FirmAssociationDetails.MmsFirmAssociation,
    user: CustomerDetails.CustomerPersonalDetails,
    removeAssociationFromList: (firm: FirmAssociationDetails.MmsFirmAssociation) => void
}

interface EndFormDetail {
    date: Date
}

function EndAssociationModal({firm, removeAssociationFromList, user}: EndAssociationModalProps) {
    const [show, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<EndFormDetail>();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const endFirmAssociation: SubmitHandler<EndFormDetail> = async (data: EndFormDetail) => {
        setErrorMessage("");
        // make call
        const req = {
            memberName: user.name + ' ' + user.surname,
            memberNumber: firm.memberNumber,
            firmName: firm.firmName,
            firmNumber: firm.firmNumber,
            statusDesc: firm.statusDesc,
            lpcId: firm.lpcId,
            endDate: Timestamp.fromDate(new Date(data.date)),
            province: user.province,
        } as FirmAssociationDetails.AssociationDetailsModel;

        // save to firestore
        await firmService.add(req).then(res => {
            if (res) {
                setLoading(false);
                SuccessMsg("Association End Request sent!");
                // trigger a refresh, remove from list
                removeAssociationFromList(firm);
            } else {
                ErrorMsg(AppConstants.DefaultErrorMessage);
            }
        }).catch(err => {
            setLoading(false);
            setErrorMessage(err.message ?? "AppConstants.DefaultErrorMessage");
            ErrorMsg(AppConstants.AuthenticationErrorMap[err.code]);
        });
    }

    const SuccessMsg = (msg: string) => {
        toast.success(msg, ToastOptionsConst.success);
    }

    const ErrorMsg = (msg: string) => {
        toast.error(msg ?? AppConstants.DefaultErrorMessage, ToastOptionsConst.error);
    }
    return (
        <>
            <button
                type="button"
                className="btn btn-lpc-danger mx-4"
                onClick={handleShow}>
                End Association
            </button>
            <Modal show={show} onHide={handleClose}>
                <form method="post" className="simple-form" onSubmit={handleSubmit(endFirmAssociation)}>
                    <Modal.Header closeButton>
                        <Modal.Title>End Firm Association?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you would like to End Association
                        with <strong>{firm.firmName}</strong> ?<br/>
                        <p className={"lpc-error-msg"}>{errorMessage}</p>
                        <hr/>
                        <div
                            className={`form-group ${errors.date ? 'has-error' : ''} `}>
                            <label>End Date with Firm</label>
                            <input type="date"
                                   className="form-control" {...register("date", {required: true})}
                                   placeholder="End Date"/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-lpc-outline"
                            disabled={isLoading}
                            onClick={handleClose}>
                            No
                        </button>
                        <button
                            type="submit"
                            className="btn btn-lpc-danger mx-2">
                            {isLoading ? "Submitting..." : "Yes"}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
