import {getFunctions, httpsCallable} from 'firebase/functions';
import {CloudFunctions} from "../constants/CloudFunctions";
import {PaymentDetails} from "../models/PaymentDetails";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    query,
    QueryConstraint,
    Timestamp,
    updateDoc,
    where
} from "firebase/firestore";
import {getAuth} from 'firebase/auth';
import {FirestoreCollection} from '../constants/FirestoreCollection';
import {subDays} from "date-fns";
import {AppConstants} from "../constants/AppConstants";

export default class PaymentService {
    private static instance: PaymentService | null = null;

    static getInstance(): PaymentService {
        if (PaymentService.instance === null) {
            PaymentService.instance = new PaymentService()
        }

        return this.instance as PaymentService;
    }

    async getCheckoutDetails(request: PaymentDetails.PaymentCheckoutRequestModel): Promise<PaymentDetails.YocoCheckoutDetails | null> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.paymentCheckout);
        const funcRef = callable(request);

        return funcRef.then(res => {
            const responseModel = res.data as PaymentDetails.PaymentCheckoutResponseModel;
            return responseModel.data as PaymentDetails.YocoCheckoutDetails;
        }).catch(error => {
            return null;
        })
    }

    async getMmsInvoiceList(request: PaymentDetails.InvoiceListRequestModel): Promise<Array<PaymentDetails.Payment> | null> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.invoiceList);
        const funcRef = callable(request);

        return funcRef.then(res => {
            const responseModel = res.data as PaymentDetails.InvoiceListResponseModel;

            const invoiceList = responseModel.data as Array<PaymentDetails.InvoiceListPayment>;
            // map invoiceList to payment
            return invoiceList.map((a: PaymentDetails.InvoiceListPayment) => {
                return {
                    ...a,
                    createdDate: Timestamp.fromDate(new Date(a.createdDate)),
                    paidDate: a.paidDate ? Timestamp.fromDate(new Date(a.paidDate)): null,
                    updatedDate: Timestamp.now(),
                    dueDate: a.dueDate ? Timestamp.fromDate(new Date(a.dueDate)) : null
                } as PaymentDetails.Payment;
            })
        }).catch(error => {
            return null;
        })
    }

    getPaymentListByUserId = async (status: PaymentDetails.AccountAndBillingSection): Promise<Array<PaymentDetails.Payment> | null> => {
        const fireStore = getFirestore(getAuth().app);
        const collectionRef = collection(fireStore, FirestoreCollection.paymentDetails);

        // base QueryConstrain
        const queryConstraint: QueryConstraint[] = [where("userId", "==", getAuth().currentUser?.uid)]
        const queryRef = status === "Paid" ?
            query(collectionRef, ...queryConstraint, where("status", "==", "Paid" as PaymentDetails.PaymentStatus)) :
            status === "AccountAndBilling" ? query(collectionRef, ...queryConstraint, where("status", "in", ["Outstanding" as PaymentDetails.PaymentStatus, "Initiated" as PaymentDetails.PaymentStatus])) : query(collectionRef, ...queryConstraint);

        const querySnapshot = await getDocs(queryRef);

        if (querySnapshot.empty) {
            return null;
        }

        const list: Array<PaymentDetails.Payment> = [];
        querySnapshot.forEach((doc) => {
            list.push({...doc.data(), paymentId: doc.id} as PaymentDetails.Payment);
        });

        return list;
    };

    updatePaymentStatusToVerifying = async (paymentId: string) => {
        const docRef = doc(getFirestore(getAuth().app), FirestoreCollection.paymentDetails, paymentId);
        const updateData = {
            status: "Verifying",
            updatedDate: Timestamp.now(),
        } as Pick<PaymentDetails.Payment, "status" | "updatedDate">;

        await updateDoc(docRef, updateData);
    }

    async getPaymentDetailById(paymentId: string): Promise<PaymentDetails.Payment | null> {
        const docRef = doc(getFirestore(getAuth().app), FirestoreCollection.paymentDetails, paymentId);
        return getDoc(docRef).then(async res => {
            if (res.exists()) {
                return {...res.data(), paymentId: res.id} as PaymentDetails.Payment;
            }
            return null;
        })
    }

    async getPaymentDetailByInvoiceNumber(invoiceNumber: string): Promise<PaymentDetails.Payment | null> {
        const currentUser = getAuth().currentUser;
        const collectionRef = collection(getFirestore(getAuth().app), FirestoreCollection.paymentDetails);
        const queryRef = query(collectionRef, where("invoiceNumber", "==", invoiceNumber),
            where("userId", "==", currentUser?.uid),
            limit(1));

        const querySnapshot = await getDocs(queryRef);
        if (querySnapshot.empty) {
            return null;
        }

        const list: Array<PaymentDetails.Payment> = [];
        querySnapshot.forEach((doc) => {
            list.push({...doc.data(), paymentId: doc.id} as PaymentDetails.Payment);
        });

        return list[0];  // first one found
    }

    async getPaymentDetailByCategoryRefId(category: PaymentDetails.PaymentCategory, categoryRefId: string): Promise<PaymentDetails.Payment | null> {
        const currentUser = getAuth().currentUser;
        const collectionRef = collection(getFirestore(getAuth().app), FirestoreCollection.paymentDetails);
        const queryRef = query(collectionRef,
            where("category", "==", category),
            where("categoryRefId", "==", categoryRefId),
            where("userId", "==", currentUser?.uid),
            limit(1));

        const querySnapshot = await getDocs(queryRef);
        if (querySnapshot.empty) {
            return null;
        }

        const list: Array<PaymentDetails.Payment> = [];
        querySnapshot.forEach((doc) => {
            list.push({...doc.data(), paymentId: doc.id} as PaymentDetails.Payment);
        });

        return list[0];  // first one found
    }

    async loadMmsInvoiceToPayment(mmsInvoice: PaymentDetails.Payment): Promise<PaymentDetails.Payment> {
        const currentUser = getAuth().currentUser;

        const detail = {
            ...mmsInvoice,
            description: "OTHER" as PaymentDetails.PaymentCategory,
            userId: currentUser?.uid,
            updatedDate: Timestamp.now(),
            dueDate: Timestamp.fromMillis(mmsInvoice.dueDate.seconds * 1000),  // to milliseonds
            createdDate: Timestamp.fromMillis(mmsInvoice.createdDate.seconds * 1000),  // to milliseonds
        } as PaymentDetails.Payment;

        const colRef = collection(getFirestore(getAuth().app), FirestoreCollection.paymentDetails);
        const docRef = await addDoc(colRef, detail);

        detail.paymentId = docRef.id;
        return detail;
    }

    async add(addDetail: PaymentDetails.Payment, memberNumber: number): Promise<PaymentDetails.Payment> {
        // First Generate GS invoice in MMS
        const invoiceResponse = await this.generateMmsInvoice({memberNumber});
        const currentUser = getAuth().currentUser;

        const detail = {
            ...addDetail,
            invoiceNumber: invoiceResponse?.invoiceNumber,
            description: addDetail.category,
            userId: currentUser?.uid,
            status: "Outstanding",
            totalAmount: invoiceResponse?.invoiceTotal,
            createdDate: Timestamp.now(),
            updatedDate: Timestamp.now(),
        } as PaymentDetails.Payment;

        const colRef = collection(getFirestore(getAuth().app), FirestoreCollection.paymentDetails);
        const docRef = await addDoc(colRef, detail);

        detail.paymentId = docRef.id;
        return detail;
    }

    private async generateMmsInvoice(request: PaymentDetails.GenerateGsInvoiceRequestModel): Promise<PaymentDetails.GenerateGsInvoiceModel | null> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.generateGsInvoice);
        const funcRef = callable(request);

        return funcRef.then(res => {
            const responseModel = res.data as PaymentDetails.GenerateGsInvoiceResponseModel;
            return responseModel.data as PaymentDetails.GenerateGsInvoiceModel;
        }).catch(error => {
            return null;
        })
    }

    // Get outstanding or initiated payments within the last year
    getOneGSOutstandingOrInitiatedPaymentInTheLastYear = async (): Promise<PaymentDetails.Payment | null> => {
        const firestore = getFirestore(getAuth().app);
        const collectionRef = collection(firestore, FirestoreCollection.paymentDetails);
        const oneYearAgo = Timestamp.fromDate(subDays(new Date(), AppConstants.ApplicationActiveDaysCount));

        const querySnapshot = await getDocs(query(collectionRef,
            where('userId', '==',  getAuth().currentUser?.uid),
            where('status', 'in', ['Outstanding', 'Initiated']),
            where('category', '==', "GS" as PaymentDetails.PaymentCategory),
            where('updatedDate', '>=', oneYearAgo), //
            limit(1)
        ));

        if (querySnapshot.empty) {
            return null;
        }

        const paymentList: PaymentDetails.Payment[] = [];

        querySnapshot.forEach((doc) => {
            paymentList.push({...doc.data(), paymentId: doc.id} as PaymentDetails.Payment);
        });

        return paymentList[0];
    };
}
