import {FirestoreCollection} from "../constants/FirestoreCollection";
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    UserCredential
} from "firebase/auth";
import {doc, getDoc, getFirestore} from "firebase/firestore";
import {getFunctions, httpsCallable,} from "firebase/functions";
import {CustomerDetails} from "../models/CustomerDetails";
import {AppSettings} from "../constants/AppSettings";
import {CloudFunctions} from "../constants/CloudFunctions";

export default class UserService {
    private static instance: UserService | null = null;

    static getInstance(): UserService {
        if (UserService.instance === null) {
            UserService.instance = new UserService();
        }

        return this.instance as UserService;
    }

    // Get cached details from Firestore collection(used for loggin in ONLY)
    async getLoginPersonalDetail(): Promise<CustomerDetails.CustomerPersonalDetails | null> {
        const currentUser = getAuth().currentUser;
        const docRef = doc(getFirestore(getAuth().app), FirestoreCollection.customerPersonalDetails, currentUser?.uid as string);
        return getDoc(docRef).then(async res => {
            if (res.exists()) {
                return res.data() as CustomerDetails.CustomerPersonalDetails;
            }
            return null;
        })
    }

    async getMemberDetails(): Promise<CustomerDetails.MemberDetailsModel | null> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.memberDetails);
        const funcRef = callable();

        return funcRef.then(res => {
            const responseModel = res.data as CustomerDetails.MemberDetailsResponseModel;
            return responseModel.isSuccess ? responseModel.data as CustomerDetails.MemberDetailsModel : null;
        }).catch(() => {
            return null;
        })
    }

    async getBussinessRules(request: CustomerDetails.BusinessRulesRequestModel): Promise<CustomerDetails.BusinessRulesModel | null> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.businessRule);
        const funcRef = callable(request);

        return funcRef.then(res => {
            const responseModel = res.data as CustomerDetails.BusinessRulesResponseModel;
            return responseModel.isSuccess ? responseModel.data as CustomerDetails.BusinessRulesModel : null;
        }).catch(() => {
            return null;
        })
    }

    isBusinessRulesPass(businessRulesModel: CustomerDetails.BusinessRulesModel | null): boolean {
        if (businessRulesModel === null) return false;
        const ffcCheck = businessRulesModel.checkRequiredFfc ? businessRulesModel.checkFfc : true;
        return businessRulesModel.checkInvoice && businessRulesModel.checkSuspension && businessRulesModel.checkStriking && businessRulesModel.checkRedflag && ffcCheck;
    }

    signInWithEmailAndPassword(cred: CustomerDetails.SignInCredentials): Promise<UserCredential> {
        const auth = getAuth();
        return signInWithEmailAndPassword(auth, this.getProxyEmailAddress(cred.identityNumber), cred.password);
    }

    memberRegistrationWithIdentityNumber(cred: CustomerDetails.RegistrationModel): Promise<CustomerDetails.MemberRegistrationResponseModel> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.memberRegistration)
        const funcResponse = callable(cred)

        return funcResponse.then(x => {
            return x.data as CustomerDetails.MemberRegistrationResponseModel;
        });
    }

    getProxyEmailAddress(identityNumber: string) {
        return identityNumber + "@" + AppSettings.emailProxyDomain;
    }

    async signOut(): Promise<void> {
        const auth = getAuth();
        return signOut(auth);
    }

    sendPasswordResetEmail(forgotPasswordRequest: CustomerDetails.MemberForgotPasswordRequestModel): Promise<CustomerDetails.MemberForgotPasswordResponseModel> {
        const callable = httpsCallable(getFunctions(), CloudFunctions.memberForgotPassword)
        const funcResponse = callable(forgotPasswordRequest)

        return funcResponse.then(x => {
            return x.data as CustomerDetails.MemberForgotPasswordResponseModel;
        });
    }
}
